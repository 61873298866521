import React from 'react';
import Divider from '../components/Divider';
import CreateUser from '../components/forms/CreateUser';
import CreateOrganization from '../components/forms/CreateOrganization';
import { useSelector } from 'react-redux';
import { RootState } from '../redux';
import { UserRole } from '../api/types';

function CreateUserOrganization() {
    const userState = useSelector((state: RootState) => state.user);

    return (
        <>
            <>
                <Divider />

                <div className='d-flex flex-column flex-xl-row col-12'>
                    <CreateUser />
                    {userState.role === UserRole.ADMIN && (
                        <>
                            <Divider orientation={window.screen.width > 992 ? 'vertical' : 'horizontal'} flexItem />
                            <CreateOrganization />
                        </>
                    )}
                </div>
            </>
        </>
    );
}

export default CreateUserOrganization;
