import React, { useRef, useState } from 'react';
import { InputBase as MuiInputBase, InputBaseProps, styled } from '@mui/material';
import { FieldHookConfig, useField } from 'formik';
import palette from '../../scss/palette.module.scss';
import { UilExclamationTriangle } from '@iconscout/react-unicons';

type Props = {
    validated?: boolean;
    label?: string;
    containerClassName?: string;
    ref?: React.ForwardedRef<HTMLDivElement>;
} & InputBaseProps &
    FieldHookConfig<string>;

const InputBase = styled(MuiInputBase)({
    display: 'flex',
    background: palette.white,
    padding: '16px 14px',
    borderRadius: '12px',
    margin: '0.5 rem 0 0.75 rem 0',
    boxShadow: '0px 0px 20px rgba(170, 170, 170, 0.15)',

    '& input': {
        width: '100%',
        display: 'flex',
        background: 'none',
        border: 'none',
        outline: 'none',
        fontFamily: 'Montserrat',
        height: 24,
        padding: 0,
        color: palette.gray2,
        '::placeholder': {
            color: palette.gray2,
            opacity: 0.75,
        },
    },

    '& .MuiInput-endAdornment': {
        display: 'flex',
    },

    '& svg': {
        color: palette.gray2,
    },
});

const InputUnvalidated = React.forwardRef(
    ({ label, containerClassName, ...props }: Props, ref: React.ForwardedRef<HTMLDivElement>) => {
        const [type, setType] = useState(props.type);
        const handleType = () =>
            props.type === 'password' ? (type === 'password' ? setType('text') : setType('password')) : null;

        return (
            <div
                className={
                    containerClassName ? `MuiInputBase-container ${containerClassName}` : 'MuiInputBase-container'
                }
            >
                {label && <label className='MuiInputBase-label'>{label}</label>}
                <InputBase
                    {...props}
                    type={type}
                    endAdornment={
                        <div onClick={handleType} className='MuiInput-endAdornment'>
                            {props.endAdornment}
                        </div>
                    }
                    ref={ref}
                />
            </div>
        );
    },
);

const InputValidated = React.forwardRef(
    ({ label, containerClassName, ...props }: Props, ref: React.ForwardedRef<HTMLDivElement>) => {
        const [type, setType] = useState(props.type);
        const [field, meta] = useField(props);

        const handleType = () =>
            props.type === 'password' ? (type === 'password' ? setType('text') : setType('password')) : null;

        return (
            <div
                className={
                    containerClassName ? `MuiInputBase-container ${containerClassName}` : 'MuiInputBase-container'
                }
            >
                {label && <label className='MuiInputBase-label'>{label}</label>}
                <div>
                    <InputBase
                        {...field}
                        {...props}
                        type={type}
                        endAdornment={
                            <div
                                onClick={handleType}
                                className={`MuiInput-endAdornment${props.type === 'password' ? ' cp' : ''}`}
                            >
                                {props.endAdornment}
                            </div>
                        }
                        ref={ref}
                    />
                </div>
                <div>
                    {meta.touched && meta.error && (
                        <span className='MuiInputBase-feedback'>
                            <UilExclamationTriangle />
                            {meta.error}
                        </span>
                    )}
                </div>
            </div>
        );
    },
);

const Input = React.forwardRef(({ validated = true, ...props }: Props, ref: React.ForwardedRef<HTMLDivElement>) => {
    const inputRef = useRef<HTMLDivElement>(null);
    return validated ? (
        <InputValidated {...props} ref={ref ? ref : inputRef} />
    ) : (
        <InputUnvalidated {...props} ref={ref ? ref : inputRef} />
    );
});

export default Input;
