import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { object, string } from 'yup';
import { createProduct } from '../../api/api';
import { Form, Formik } from 'formik';
import { CreateProduct as CreateProductType } from '../../api/types';
import { setAlert } from '../../redux/slices/AlertSlice';
import Input from './Input';
import { Puff } from '@agney/react-loading';

interface InitialValues {
    name: string;
    description: string;
    tag: string;
}

const initialValues: InitialValues = {
    name: '',
    description: '',
    tag: '',
};

function CreateProduct() {
    const dispatch = useDispatch();

    const [isLoading, setLoading] = useState<boolean>(false);

    return (
        <div className='col-6'>
            <Formik
                initialValues={initialValues}
                validationSchema={object({
                    name: string().required(),
                    description: string().required(),
                    tag: string().required(),
                })}
                onSubmit={(values, { resetForm }) => {
                    setLoading(true);
                    const product = { ...values };
                    createProduct(product as CreateProductType)
                        .then((res) => res.data)
                        .then((data) => {
                            resetForm();
                            setLoading(false);
                            dispatch(
                                setAlert({
                                    isActive: true,
                                    message: data.message,
                                }),
                            );
                        })
                        .catch((e) => {
                            setLoading(false);
                            dispatch(
                                setAlert({
                                    isActive: true,
                                    message: e.response.data.message,
                                }),
                            );
                        });
                }}
            >
                <Form>
                    <Input name='name' label='Nombre' placeholder='Ej.: Tapsin' type='text' />
                    <Input
                        name='description'
                        label='Descripción'
                        placeholder='Ej.: Producto recubierto...'
                        type='text'
                    />
                    <Input name='tag' label='Etiquetas' placeholder='Ej.: Dolores Generales' type='text' />
                    <button className='primary-button'>
                        {isLoading && (
                            //@ts-ignore
                            <Puff width={20} />
                        )}
                        {isLoading ? 'Creando Producto' : 'Crear Producto'}
                    </button>
                </Form>
            </Formik>
        </div>
    );
}

export default CreateProduct;
