import React, { useEffect, useState } from 'react';
import { getCurrentChats, getMyOrganization, Last5Organizations } from '../api/api';
import { Link } from 'react-router-dom';
import { setOrganization as setOrganizationAct } from '../redux/slices/OrganizationSlice';
import { useDispatch } from 'react-redux';
import GradientButton from '../components/GradientButton';

import svg from '../assets/no-row.svg';
import { Bars } from '@agney/react-loading';

function Dashboard() {
    const [organizations, setOrganization] = useState<Array<any>>([]);
    const [currentChats, setCurrentChats] = useState<Array<any>>([]);
    const [loading, setLoading] = useState<boolean>(true);

    const dispatch = useDispatch();

    useEffect(() => {
        getMyOrganization()
            .then((res) => res.data)
            .then((data) => {
                dispatch(setOrganizationAct(data.data));
            })
            .finally(() => {
                getCurrentChats(5)
                    .then((res) => res.data)
                    .then((data) => {
                        setCurrentChats(data.content.slice(0, 5));
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            });

        return () => {
            setCurrentChats([]);
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        Last5Organizations()
            .then((res) => res.data)
            .then((data) => {
                setOrganization(data.content);
            })
            .catch(() => {});
        return () => {
            setOrganization([]);
        };
    }, []);

    return (
        <div className='d-flex flex-column '>
            {loading ? (
                <div className='loader-float'>
                    {/*@ts-ignore*/}
                    <Bars width='100' color={'#BF38FF'} />
                </div>
            ) : (
                <div className='d-flex flex-column flex-lg-row justify-content-between h-100'>
                    <div className='col-12 col-lg-6 col-xxl-6 d-flex flex-column '>
                        <div className='d-flex flex-column my-3  '>
                            <div className='my-4 d-flex '>
                                <h5 className='title-pill '>Ultimas Solicitudes</h5>
                                <Link className='mx-3' to='/admin/organizaciones'>
                                    Ver todos
                                </Link>
                            </div>
                            {organizations.length !== 0 ? (
                                organizations.map((organization, key) => {
                                    return (
                                        <Link
                                            to={`/admin/organizacion/${organization.id}`}
                                            key={key}
                                            className='organization-card cp'
                                        >
                                            <div className='left-side'>
                                                <h5>{organization.name}</h5>
                                                <p>{organization.address}</p>
                                            </div>
                                            <div className='right-side'>
                                                {organization.sentToSellCount !== 0 ? (
                                                    <div className='request'>
                                                        <p className='text-white'>Solicitud de venta</p>
                                                    </div>
                                                ) : (
                                                    organization.expireInCount !== 0 && (
                                                        <div className='expires'>
                                                            <p className='text-white '>
                                                                {organization.expireInCount}{' '}
                                                                {organization.expireInCount === 1
                                                                    ? 'producto'
                                                                    : 'productos'}{' '}
                                                                por vencer
                                                            </p>
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                        </Link>
                                    );
                                })
                            ) : (
                                <>
                                    <div className='mx-auto h-50 w-50'>
                                        <img src={svg} alt='asdas' />
                                    </div>
                                </>
                            )}
                        </div>
                        <div className='mt-auto'>
                            <GradientButton
                                className={'w-100 my-3  align-items-center'}
                                imgClassName={'me-3'}
                                to={'/admin/crear'}
                                color={'blue'}
                                src={'users.svg'}
                                vertical={false}
                                title={'Añadir usuarios'}
                                subtitle={'Crea usuarios y dales acceso a la plataforma.'}
                            />
                        </div>
                    </div>
                    <div className='d-flex flex-column col-12 col-lg-5 col-xxl-5 justify-content-between '>
                        <div className='d-flex flex-column my-3'>
                            <div className='my-4 d-flex  '>
                                <h5 className='title-pill '>Chat activos</h5>
                                <Link className='mx-3' to='/chat'>
                                    Ver todos
                                </Link>
                            </div>
                            {currentChats.map((chat, key) => {
                                return (
                                    <Link to={`/chat`} key={key} className='organization-card cp'>
                                        <div className='left-side'>
                                            <h5>{chat.name}</h5>
                                            <p>{chat.address}</p>
                                        </div>
                                        <div className='right-side'>
                                            {chat.unreadMessageCount === 0 ? (
                                                <div className='message-void'>
                                                    <p> - </p>
                                                </div>
                                            ) : (
                                                <div className='message-count'>
                                                    <p className='count'>{chat.unreadMessageCount}</p>
                                                    <p className='text-white'>Mensajes sin leer</p>
                                                </div>
                                            )}
                                        </div>
                                    </Link>
                                );
                            })}
                        </div>
                        <div className='mt-auto '>
                            <GradientButton
                                className={'w-100 my-3  align-items-center'}
                                imgClassName={'me-3'}
                                to={'/chat'}
                                color={'blue'}
                                src={'chat.svg'}
                                vertical={false}
                                title={'Chat'}
                                subtitle={'Contactate con tus clientes, y resuelve sus problemas.'}
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Dashboard;
