import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import {
    UilApps,
    UilBill,
    UilCapsule,
    UilHospital,
    UilLinkBroken,
    UilSignout,
    UilUsersAlt,
} from '@iconscout/react-unicons';
import { setLogout } from '../../api/api';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux';
import { CSSObject, Drawer as MuiDrawer, styled, Theme, Toolbar as MuiToolbar } from '@mui/material';
import palette from '../../scss/palette.module.scss';
import { UserRole } from '../../api/types';

interface Props {
    children: React.ReactNode;
}

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: '65px',
});

const Drawer = styled(MuiDrawer)(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',

    ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': {
            ...openedMixin(theme),
            background: palette.white,
            border: 'none',
        },
    }),
    ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': {
            ...closedMixin(theme),
            background: palette.white,
            border: 'none',
        },
    }),

    '& .MuiDrawer-logo': {
        display: 'flex',
        justifyContent: 'center',
        margin: '25px auto',

        '&-icon': {
            display: 'flex',
            alignItems: 'center',
            width: 32,
        },

        //TODO: Arreglar cuando se use el logo oficial de Pilldomatch
        '&-brand': {
            display: 'flex',
            alignItems: 'center',
            width: open ? '100%' : 0,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
        },
    },

    '& .MuiDrawer-links': {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'space-between',
    },

    '& .MuiDrawer-items': {
        display: 'flex',
        flexDirection: 'column',
    },

    '& .MuiDrawer-item': {
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        height: 48,
        paddingLeft: '1.25rem',
        fontWeight: 500,
        color: palette.gray3,
        margin: '1rem 0',

        '&.active': {
            color: palette.primary,

            '&::before': {
                content: '""',
                position: 'absolute',
                left: 0,
                height: 48,
                width: 8,
                background: '#BD37FC',
                borderTopRightRadius: 16,
                borderBottomRightRadius: 16,
            },
        },

        '&-icon': {
            display: 'flex',
            paddingRight: open ? 8 : 32,
            flexShrink: 0,
            transition: theme.transitions.create('padding', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
        },
    },

    '& .MuiDrawer-logout': {
        '& .MuiDrawer-item': {
            cursor: 'pointer',
            color: palette.error,
        },
    },
}));

const activeClassName: string = 'active';

const renderContent = (role: UserRole) => {
    switch (role) {
        case UserRole.ADMIN:
            return (
                <div className='MuiDrawer-items'>
                    <NavLink
                        to='/admin'
                        className={({ isActive }) =>
                            isActive ? `MuiDrawer-item ${activeClassName}` : 'MuiDrawer-item'
                        }
                        end
                    >
                        <div className='MuiDrawer-item-icon'>
                            <UilApps />
                        </div>
                        <div className='MuiDrawer-item-label'>Dashboard</div>
                    </NavLink>
                    <NavLink
                        to='/admin/organizaciones'
                        className={({ isActive }) =>
                            isActive ? `MuiDrawer-item ${activeClassName}` : 'MuiDrawer-item'
                        }
                        end
                    >
                        <div className='MuiDrawer-item-icon'>
                            <UilHospital />
                        </div>
                        <div className='MuiDrawer-item-label'>Farmacias</div>
                    </NavLink>
                    <NavLink
                        to='/admin/usuarios'
                        className={({ isActive }) =>
                            isActive ? `MuiDrawer-item ${activeClassName}` : 'MuiDrawer-item'
                        }
                    >
                        <div className='MuiDrawer-item-icon'>
                            <UilUsersAlt />
                        </div>
                        <div className='MuiDrawer-item-label'>Usuarios</div>
                    </NavLink>
                </div>
            );
        case UserRole.MODERATOR:
            return (
                <div className='MuiDrawer-items'>
                    <NavLink
                        to='/mod'
                        className={({ isActive }) =>
                            isActive ? `MuiDrawer-item ${activeClassName}` : 'MuiDrawer-item'
                        }
                        end
                    >
                        <div className='MuiDrawer-item-icon'>
                            <UilApps />
                        </div>
                        <div className='MuiDrawer-item-label'>Dashboard</div>
                    </NavLink>
                    <NavLink
                        to='/mod/usuarios'
                        className={({ isActive }) =>
                            isActive ? `MuiDrawer-item ${activeClassName}` : 'MuiDrawer-item'
                        }
                    >
                        <div className='MuiDrawer-item-icon'>
                            <UilUsersAlt />
                        </div>
                        <div className='MuiDrawer-item-label'>Usuarios</div>
                    </NavLink>
                    <NavLink
                        to='/mod/inventario'
                        className={({ isActive }) =>
                            isActive ? `MuiDrawer-item ${activeClassName}` : 'MuiDrawer-item'
                        }
                    >
                        <div className='MuiDrawer-item-icon'>
                            <UilCapsule />
                        </div>
                        <div className='MuiDrawer-item-label'>Inventario</div>
                    </NavLink>
                    <NavLink
                        to='/mod/pronto-vence'
                        className={({ isActive }) =>
                            isActive ? `MuiDrawer-item ${activeClassName}` : 'MuiDrawer-item'
                        }
                    >
                        <div className='MuiDrawer-item-icon'>
                            <UilLinkBroken />
                        </div>
                        <div className='MuiDrawer-item-label'>Pronto vence</div>
                    </NavLink>
                    <NavLink
                        to='/mod/solicitud-de-venta'
                        className={({ isActive }) =>
                            isActive ? `MuiDrawer-item ${activeClassName}` : 'MuiDrawer-item'
                        }
                    >
                        <div className='MuiDrawer-item-icon'>
                            <UilBill />
                        </div>
                        <div className='MuiDrawer-item-label'>Solicitudes de Venta</div>
                    </NavLink>
                </div>
            );
        case UserRole.USER:
            return (
                <div className='MuiDrawer-items'>
                    <NavLink
                        to='/dashboard'
                        className={({ isActive }) =>
                            isActive ? `MuiDrawer-item ${activeClassName}` : 'MuiDrawer-item'
                        }
                        end
                    >
                        <div className='MuiDrawer-item-icon'>
                            <UilApps />
                        </div>
                        <div className='MuiDrawer-item-label'>Dashboard</div>
                    </NavLink>
                    <NavLink
                        to='/inventario'
                        className={({ isActive }) =>
                            isActive ? `MuiDrawer-item ${activeClassName}` : 'MuiDrawer-item'
                        }
                    >
                        <div className='MuiDrawer-item-icon'>
                            <UilCapsule />
                        </div>
                        <div className='MuiDrawer-item-label'>Productos</div>
                    </NavLink>
                    <NavLink
                        to='/pronto-vence'
                        className={({ isActive }) =>
                            isActive ? `MuiDrawer-item ${activeClassName}` : 'MuiDrawer-item'
                        }
                    >
                        <div className='MuiDrawer-item-icon'>
                            <UilLinkBroken />
                        </div>
                        <div className='MuiDrawer-item-label'>Pronto vence</div>
                    </NavLink>
                    <NavLink
                        to='/solicitud-de-venta'
                        className={({ isActive }) =>
                            isActive ? `MuiDrawer-item ${activeClassName}` : 'MuiDrawer-item'
                        }
                    >
                        <div className='MuiDrawer-item-icon'>
                            <UilBill />
                        </div>
                        <div className='MuiDrawer-item-label'>Solicitudes de Venta</div>
                    </NavLink>
                </div>
            );
    }
};

const Toolbar = styled(MuiToolbar)({
    padding: 0,
});

function Sidebar({ children }: Props) {
    const userState = useSelector((state: RootState) => state.user);
    const sidebarState = useSelector((state: RootState) => state.sidebar);

    const navigate = useNavigate();

    const logout = () => {
        setLogout();
        navigate('/', { replace: true });
    };

    return (
        <>
            <Toolbar />

            <Drawer variant='permanent' open={sidebarState.isActive}>
                <Toolbar />
                <div className='MuiDrawer-links'>
                    {renderContent(userState.role)}
                    <div className='MuiDrawer-logout'>
                        <div className='MuiDrawer-item' onClick={logout}>
                            <div className='MuiDrawer-item-icon'>
                                <UilSignout />
                            </div>
                            <div className='MuiDrawer-item-label'>Cerrar Sesión</div>
                        </div>
                    </div>
                </div>
            </Drawer>
            <main className='main'>
                <Toolbar />
                {children}
            </main>
        </>
    );
}

export default Sidebar;
