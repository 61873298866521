import moment from 'moment';
import 'moment/locale/es';

export function formatDate(timestamp: string) {
    let gmtDateTime = moment(timestamp ?? new Date(), 'YYYY-MM-DD HH:mm');
    // return gmtDateTime.local().fromNow();
    return gmtDateTime.local().format('h:mm:ss a');
}

export function formatExpirationDate(date: string) {
    const newDate = moment(date, 'YYYY-MM-DD');
    return newDate.format('DD/MM/YYYY');
}

export function formatDateCalendar(timestamp: string) {
    let gmtDateTime = moment.utc(timestamp ?? new Date(), 'YYYY-MM-DD HH:mm');
    return gmtDateTime.local().calendar();
}
