import { GridSortModel } from '@mui/x-data-grid';

export interface LoginUser {
    email: string;
    password: string;
}

export interface CreateUser {
    email: string;
    password: string;
    firstName: string;
    lastName: string;
    role: string;
    organization: string;
}

export interface UpdateUser {
    email?: string;
    firstName?: string;
    lastName?: string;
    role?: string;
}

export interface CreateOrganization {
    name: string;
    expirationCriteria: number;
    address: string;
}

export interface UpdateOrganization extends Partial<CreateOrganization> {}

export interface AccessToken {
    accessToken: string;
}

export interface PaginationSearch {
    page: number;
    limit?: number;
    value: string;
    orgId?: string;
}

export interface User {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    role: UserRole;
    organization?: Organization;
}

export interface Organization {
    id: string;
    name: string;
    expirationCriteria: number;
    address: string;
    createdAt: string;
    usersCount: number;
    expireInCount: number;
    expiredCount: number;
    sentToSellCount: number;
}

export interface Store {
    id: string;
    expiration: string;
    batch: string;
    quantity: number;
    value: number;
    product: {
        id: string;
        name: string;
        tag: string;
    };
    expireIn: string;
    expired: boolean;
}

export interface Product {
    id: string;
    description: string;
    name: string;
    tag: string;
}

export interface AddProduct {
    batch: string;
    expiration: string;
    value: number;
    quantity: number;
    productId: string;
}

export interface CreateProduct {
    name: string;
    description: string;
    tag: string;
}

export interface Message {
    message: string;
}

export interface Contents<T> {
    content: Array<T>;
    paging: Paging;
}

export interface Data<T> {
    data: T;
}

export interface Paging {
    next?: string;
    maxItems: number;
    previous?: string;
}

export interface Search {
    q: string;
    page: number;
    limit?: number;
    sort: GridSortModel;
    bySaleRequest?: boolean;
}

export interface SearchStore extends Search {
    organization: string;
    soldOut: boolean;
}

export interface Token {
    sub: string;
    role: UserRole;
    organization: string;
    email: string;
    fullName: string;
}

export enum UserRole {
    ADMIN = 'admin',
    MODERATOR = 'moderator',
    USER = 'user',
}
