import React from 'react';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { UilEllipsisV } from '@iconscout/react-unicons';
import { markStoreAsSold } from '../api/api';
import { setAlert } from '../redux/slices/AlertSlice';
import { useDispatch } from 'react-redux';

interface Props {
    params: GridRenderCellParams;
    isAdmin?: boolean;
}

const ProductActionCell = ({ params, isAdmin = false }: Props) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    const dispatch = useDispatch();

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <>
            <IconButton onClick={handleClick}>
                <UilEllipsisV />
            </IconButton>
            <Menu
                id={params.row.id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                sx={{
                    boxShadow: '0 0 10px rgba(0,0,0,0.1)',
                    '& .MuiMenu-paper': {
                        boxShadow: '0 0 20px rgba(0,0,0,0.05)',
                    },
                }}
            >
                {isAdmin && !params.row.soldOut ? (
                    <>
                        <MenuItem>Comprar</MenuItem>
                        <MenuItem
                            onClick={() => {
                                markStoreAsSold([params.row.id], true)
                                    .then((res) => res.data)
                                    .then(() => {
                                        dispatch(
                                            setAlert({
                                                isActive: true,
                                                message: 'Producto cancelado (Recuerda refrescar)',
                                            }),
                                        );
                                    })
                                    .catch(() => {
                                        dispatch(
                                            setAlert({
                                                isActive: true,
                                                message: 'Se ha presentado un problema, intentelo mas tarde.',
                                            }),
                                        );
                                    });
                            }}
                        >
                            Cancelar solicitud
                        </MenuItem>
                    </>
                ) : (
                    <div>
                        <>
                            <MenuItem
                                onClick={() => {
                                    markStoreAsSold([params.row.id], true)
                                        .then((res) => res.data)
                                        .then(() => {
                                            dispatch(
                                                setAlert({
                                                    isActive: true,
                                                    message: 'Producto cancelado (Recuerda refrescar)',
                                                }),
                                            );
                                        })
                                        .catch(() => {
                                            dispatch(
                                                setAlert({
                                                    isActive: true,
                                                    message: 'Se ha presentado un problema, intentelo mas tarde.',
                                                }),
                                            );
                                        });
                                }}
                            >
                                Cancelar solicitud
                            </MenuItem>
                        </>
                    </div>
                )}
            </Menu>
        </>
    );
};

export default ProductActionCell;
