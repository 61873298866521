import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { findOrganization } from '../api/api';
import { OrganizationState } from '../redux/slices/OrganizationSlice';

import GradientButton from '../components/GradientButton';
import LoadingScreen from './LoadingScreen';
import { Bars } from '@agney/react-loading';
import palette from '../scss/palette.module.scss';

function Organization() {
    const { id } = useParams();

    const [organization, setOrganization] = useState<Partial<OrganizationState>>({});

    useEffect(() => {
        if (id !== undefined) {
            findOrganization(id)
                .then((res) => res.data)
                .then((data) => {
                    setOrganization(data.data);
                })
                .catch(() => {});
        }
        return () => {
            setOrganization({});
        };
    }, [id]);
    return (
        <div className=''>
            {!organization.id ? (
                <div className='loader-float'>
                    {/*@ts-ignore*/}
                    <Bars width='100' color={'#BF38FF'} />
                </div>
            ) : (
                <div className='d-flex flex-column flex-lg-row justify-content-between py-3'>
                    <div className='col-12 col-lg-9'>
                        <div className='d-flex justify-content-between'>
                            <div className='d-flex flex-column'>
                                <h4 className=''>{organization.name}</h4>
                                <p className='subtitle-1 text-black-50'>{organization.address}</p>
                                <hr />
                                <GradientButton
                                    className={'w-100 my-3 h-25 align-items-center'}
                                    to={`/admin/inventario/${organization.name}/${organization.id}`}
                                    color={'blue'}
                                    src={'inventory.svg'}
                                    vertical={false}
                                    title={'Ver inventario'}
                                    subtitle={'Añade productos a tu inventario, y ten control sobre ello'}
                                />
                                <GradientButton
                                    className={'w-100 my-3 h-25 align-items-center'}
                                    to={`/admin/solicitud-de-venta/${organization.id}`}
                                    color={'pink'}
                                    src={'sale-request.svg'}
                                    vertical={false}
                                    title={'Solicitudes de venta'}
                                    subtitle={'Solicitudes pendientes de productos pronto vence'}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='flex-fill d-flex flex-column  flex-column align-items-end'>
                        <h5>Información</h5>
                        <p className='subtitle-1 text-black-50'>
                            Criterio de expiración: {organization.expirationCriteria}
                        </p>
                        <p className='subtitle-1 text-black-50'>Pronto vence: {organization.expireInCount}</p>
                        <p className='subtitle-1 text-black-50'>Usuarios: {organization.usersCount}</p>
                        <p className='subtitle-1 text-black-50'>
                            {organization.sentToSellCount !== 0 ? 'Solicitud de venta pendiente' : ''}
                        </p>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Organization;
