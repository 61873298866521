import React, { useEffect, useState } from 'react';
import { object, string } from 'yup';
import { createUser, findOrganizationByName } from '../../api/api';
import { setAlert } from '../../redux/slices/AlertSlice';
import { Form, Formik } from 'formik';
import Input from './Input';
import { UilEye } from '@iconscout/react-unicons';
import Autocomplete, { AutocompleteValue, ListboxComponent } from './Autocomplete';
import { Puff } from '@agney/react-loading';
import { useDispatch, useSelector } from 'react-redux';
import useDebounce from '../../utils/useDebounce';
import { CreateUser as CreateUserType, UserRole } from '../../api/types';
import { RootState } from '../../redux';

interface InitialValues {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    role:
        | {
              label: string;
              value: string;
          }
        | string
        | null;
    organization:
        | {
              label: string;
              value: string;
          }
        | string
        | null;
}

const initialValues: InitialValues = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    role: null,
    organization: null,
};

function CreateUser() {
    const dispatch = useDispatch();
    const userState = useSelector((state: RootState) => state.user);
    const organizationState = useSelector((state: RootState) => state.organization);

    const [isOpen, setOpen] = useState<boolean>(false);
    const [isAutocompleteLoading, setAutocompleteLoading] = useState<boolean>(false);
    const [isLoading, setLoading] = useState<boolean>(false);
    const [organizations, setOrganizations] = useState<Array<AutocompleteValue<string, string>>>([]);
    const [search, setSearch] = useState('');
    const debouncedSearch = useDebounce(search, 500);

    const roles =
        userState.role === UserRole.ADMIN
            ? [
                  {
                      label: 'Admin',
                      value: 'admin',
                  },
                  {
                      label: 'Moderador',
                      value: 'moderator',
                  },
                  {
                      label: 'Usuario',
                      value: 'user',
                  },
              ]
            : [
                  {
                      label: 'Moderador',
                      value: 'moderator',
                  },
                  {
                      label: 'Usuario',
                      value: 'user',
                  },
              ];

    useEffect(() => {
        if (isOpen) {
            if (userState.role !== UserRole.ADMIN) {
                setOrganizations([{ label: `${organizationState.name}`, value: `${organizationState.id}` }]);
            } else {
                setOrganizations([]);
                callback();
            }
        }

        return () => {
            setAutocompleteLoading(false);
            setOrganizations([]);
        };
        // eslint-disable-next-line
    }, [isOpen, debouncedSearch]);

    const callback = () => {
        setAutocompleteLoading(true);
        findOrganizationByName(search)
            .then((res) => res.data)
            .then((data) => {
                setAutocompleteLoading(false);
                setOrganizations(
                    data.content.map((organization) => ({
                        label: organization.name,
                        value: organization.id,
                    })),
                );
            })
            .catch(() => {
                setAutocompleteLoading(false);
            });
    };

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <div className='d-flex justify-content-center w-100'>
            <div className='w-100'>
                <Formik
                    initialValues={initialValues}
                    validationSchema={object({
                        firstName: string().required(),
                        lastName: string().required(),
                        email: string().email().required(),
                        password: string().required(),
                        role: object({
                            label: string().required(),
                            value: string().required(),
                        }).typeError('Campo Obligatorio'),
                        organization: object({
                            label: string().required(),
                            value: string().required(),
                        }).typeError('Campo Obligatorio'),
                    })}
                    onSubmit={(values, { resetForm }) => {
                        setLoading(true);
                        const user = { ...values };
                        if (typeof user.organization === 'object' && user.organization !== null)
                            user.organization = user.organization.value;
                        if (typeof user.role === 'object' && user.role !== null) user.role = user.role.value;
                        createUser(user as CreateUserType)
                            .then((res) => res.data)
                            .then((data) => {
                                resetForm();
                                setLoading(false);
                                dispatch(
                                    setAlert({
                                        isActive: true,
                                        message: data.message,
                                    }),
                                );
                            })
                            .catch((e) => {
                                setLoading(false);
                                dispatch(
                                    setAlert({
                                        isActive: true,
                                        message: e.response.data.message,
                                    }),
                                );
                            });
                    }}
                >
                    <Form autoComplete='off' noValidate>
                        <Input name='firstName' label='Nombre' placeholder='Ej:. Juan' type='text' />
                        <Input name='lastName' label='Apellido' placeholder='Ej:. Perez' type='text' />
                        <Input name='email' label='Email' placeholder='Ej:. juan@pilldomatch.com' type='email' />
                        <Input
                            name='password'
                            label='Contraseña'
                            placeholder='••••••••••••'
                            type='password'
                            endAdornment={<UilEye />}
                            autoComplete='new-password'
                        />
                        <Autocomplete
                            name='role'
                            label='Rol'
                            placeholder='Ej.: Moderador'
                            disablePortal
                            options={roles}
                            getOptionLabel={(option) => option.label}
                            isOptionEqualToValue={(option, value) => option.value === value.value}
                        />
                        <Autocomplete
                            name='organization'
                            label='Farmacia'
                            placeholder='Ej.: Pilldomatch'
                            disablePortal
                            disableListWrap
                            ListboxComponent={ListboxComponent}
                            onOpen={handleOpen}
                            onClose={handleClose}
                            handleChange={setSearch}
                            open={isOpen}
                            loading={isAutocompleteLoading}
                            options={organizations}
                            renderGroup={(params) => params}
                            renderOption={(props, option) => [props, option.label]}
                            isOptionEqualToValue={(option, value) => option.value === value.value}
                        />
                        <button className='primary-button'>
                            {isLoading && (
                                //@ts-ignore
                                <Puff width={20} />
                            )}
                            {isLoading ? 'Creando Usuario' : 'Crear Usuario'}
                        </button>
                    </Form>
                </Formik>
            </div>
        </div>
    );
}

export default CreateUser;
