import React, { ChangeEvent, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import {
    getLabelsOfMonthSelected,
    getSaleRequestLabelsDates,
    getStoreRequestSelected,
    markStoreAsSold,
} from '../api/api';
import { useDispatch, useSelector } from 'react-redux';
import {
    months,
    setLabels,
    setMonthsAndYearSelected,
    setMonthSelected,
    setRequestSelected,
    setYear,
} from '../redux/slices/SaleRequestSlice';
import { RootState } from '../redux';
import moment from 'moment';
import DataGrid, { NoRowsOverlay, Toolbar } from '../components/DataGrid';
import { GridColDef, GridRowModel, GridRowParams, GridSelectionModel, GridSortModel } from '@mui/x-data-grid';
import * as Unicons from '@iconscout/react-unicons';
import ProductActionCell from '../components/ProductActionCell';
import useDebounce from '../utils/useDebounce';
import { setAlert } from '../redux/slices/AlertSlice';
import { formatDate, formatExpirationDate } from '../utils/format-date';
import { Bars } from '@agney/react-loading';
import palette from '../scss/palette.module.scss';
import { UserRole } from '../api/types';
import { UilSync } from '@iconscout/react-unicons';
import { Popover, Typography } from '@mui/material';

function SaleRequest() {
    const { organizationId } = useParams();
    const dispatch = useDispatch();
    const saleRequestState = useSelector((state: RootState) => state.saleRequest);
    const organizationState = useSelector((state: RootState) => state.organization);
    const userState = useSelector((state: RootState) => state.user);
    const [requestedAt, setRequestedAt] = useState<string>('');
    const [loadingPage, setLoadingPage] = useState<boolean>(true);

    // DATA-GRID
    const [page, setPage] = useState<number>(0);
    const [rowCount, setRowCount] = useState<number>(0);
    const [rows, setRows] = useState<Array<GridRowModel>>([]);
    const [sortModel, setSortModel] = useState<GridSortModel>([]);
    const [isLoading, setLoading] = useState<boolean>(false);
    const [search, setSearch] = useState<string>('');
    const debouncedSearch = useDebounce(search, 500);
    const [error, setError] = useState(false);

    const [isSending, setSending] = useState<boolean>(false);

    const [monthSelectedLabel, setMonthSelectedLabel] = useState<number>(-1);

    const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);

    const [callApiCallBack, setCallApiCallBack] = useState<boolean>(false);

    useEffect(() => {
        getSaleRequestLabelsDates(`${organizationId ?? organizationState.id}`)
            .then((res) => res.data)
            .then((data) => {
                setLoadingPage(false);
                dispatch(setYear({ year: data.content }));
            })
            .catch(() => {
                setLoadingPage(false);
                setError(true);
            });
        // eslint-disable-next-line
    }, []);

    function apiCall(month: number) {
        if (saleRequestState.yearSelected !== undefined) {
            getLabelsOfMonthSelected(`${organizationId ?? organizationState.id}`, saleRequestState.yearSelected, month)
                .then((res) => res.data)
                .then((data) => {
                    dispatch(setLabels({ labels: data.content }));
                })
                .catch(() => {
                    setError(true);
                });
        }
    }

    useEffect(() => {
        callback(requestedAt);
        // eslint-disable-next-line
    }, [sortModel, page, debouncedSearch, requestedAt, callApiCallBack]);

    const handlePageChange = (page: number) => {
        setRows([]);
        setPage(page);
    };

    const handleSortModelChange = (model: GridSortModel) => {
        setSortModel(model);
    };

    const callback = (requestSelected: string) => {
        setLoading(true);
        getStoreRequestSelected(
            `${organizationId ?? organizationState.id}`,
            requestSelected,
            page,
            debouncedSearch,
            sortModel,
        )
            .then((res) => res.data)
            .then((data) => {
                setLoading(false);
                setRowCount(data.paging.maxItems);
                setRows(
                    data.content.map((store: any) => ({
                        ...store,
                        id: store.id,
                        product: store.product.name,
                        expireIn: store.expireIn,
                        expiration: formatExpirationDate(store.expiration),
                        batch: store.batch,
                        value: store.value,
                        quantity: store.quantity,
                        expired: store.expired,
                    })),
                );
            })
            .catch(() => {
                setLoading(false);
                setRowCount(0);
                setRows([]);
            });
    };
    const columns: GridColDef[] = [
        { field: 'product', headerName: 'Producto', flex: 1 },
        { field: 'expireIn', headerName: 'Expira en', flex: 1, sortable: false },
        { field: 'expiration', headerName: 'Fecha de expiración', flex: 1 },
        { field: 'batch', headerName: 'Lote', flex: 1 },
        { field: 'value', headerName: 'Valor', flex: 1 },
        { field: 'quantity', headerName: 'Existencias', flex: 1 },
        {
            disableColumnMenu: true,
            disableReorder: true,
            sortable: false,
            field: 'Settings',
            headerName: 'Ajustes',
            renderHeader: () => (
                <div style={{ padding: '0 14px' }}>
                    <Unicons.UilSetting color={'#AEB7BD'} />
                </div>
            ),
            renderCell: (params) =>
                !params.row.soldOut && <ProductActionCell params={params} isAdmin={userState.isAdmin} />,
            hide: userState.role === UserRole.USER,
        },
    ];

    function buyStoreProducts() {
        if (selectionModel.length !== 0) {
            setSending(true);
            markStoreAsSold(selectionModel)
                .then((res) => res.data)
                .then(() => {
                    setSelectionModel([]);
                    dispatch(setAlert({ isActive: true, message: 'Comprados exitosamente' }));
                    setPage(0);
                    callback(requestedAt);
                    setSending(false);
                })
                .catch(() => {
                    setSending(false);
                    dispatch(setAlert({ isActive: true, message: 'Se ha presentado un error, intentalo mas tarde.' }));
                });
        }
    }

    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    const [value, setValue] = React.useState('');

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setValue(event.currentTarget.innerText);
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <div className='d-flex flex-column my-3 h-75'>
            {loadingPage ? (
                <div className='w-100 h-100 d-flex justify-content-center align-items-center'>
                    {/*//@ts-ignore*/}
                    <Bars width='100' color={palette.gray2} />
                </div>
            ) : error || saleRequestState.year === undefined ? (
                <div className='d-flex flex-column w-100 h-100 '>
                    <div className='img-container-not-found m-auto d-flex flex-column align-items-center'>
                        <img src={require('../assets/sale-request-not-found.svg').default} alt='' />
                        <p>No cuentas con solicitudes actualmente</p>
                    </div>
                </div>
            ) : (
                <>
                    <div className='sale-request-container'>
                        <div className='d-flex justify-content-between flex-row-reverse align-items-center mb-2'>
                            <div className='d-flex'>
                                <span className='sold-out-indicator me-2' />
                                <p>Solicitudes de ventas sin compras pendientes</p>
                            </div>
                            {saleRequestState.yearSelected !== undefined && monthSelectedLabel >= 0 && (
                                <div
                                    className='cp d-flex '
                                    onClick={() => {
                                        apiCall(monthSelectedLabel);
                                        setCallApiCallBack(!callApiCallBack);
                                        setSelectionModel([]);
                                    }}
                                >
                                    {/*//@ts-ignore*/}
                                    <UilSync width={'40'} color={'#BF38FF'} />
                                    <p>Refrescar</p>
                                </div>
                            )}
                        </div>

                        <div className='d-flex flex-column flex-lg-row align-items-center  '>
                            <div className='d-flex flex-lg-row flex-column align-items-center me-0 me-lg-5 '>
                                <div className='year-selector me-0 me-lg-3'>
                                    {saleRequestState.year.map((year, key) => (
                                        <div
                                            key={key}
                                            className={`item ${
                                                year.year === saleRequestState.yearSelected ? 'active' : ''
                                            }`}
                                            onClick={() =>
                                                dispatch(setMonthsAndYearSelected({ yearSelected: year.year }))
                                            }
                                        >
                                            <p>{year.year}</p>
                                        </div>
                                    ))}
                                </div>
                                <p className='text-black-50 pb-3'>
                                    {saleRequestState.yearSelected ? ' Seleccionado' : ' Selecciona el año'}
                                </p>
                            </div>
                            <div className='d-flex flex-lg-row flex-column align-items-center '>
                                {saleRequestState.months !== undefined ? (
                                    <>
                                        <div className='year-selector me-0 me-lg-3'>
                                            {saleRequestState.months.map((month, key) => (
                                                <div
                                                    key={key}
                                                    className={`item ${
                                                        month === saleRequestState.monthSelected ? 'active' : ''
                                                    } `}
                                                    onClick={() => {
                                                        dispatch(setMonthSelected({ monthSelected: month }));
                                                        setMonthSelectedLabel(month);
                                                        apiCall(month);
                                                    }}
                                                >
                                                    <p>{months[month - 1]}</p>
                                                </div>
                                            ))}
                                        </div>
                                        <p className='text-black-50 pb-3'>
                                            {saleRequestState.monthSelected ? ' Seleccionado' : ' Selecciona el mes'}
                                        </p>
                                    </>
                                ) : null}
                            </div>
                            {userState.isAdmin ? (
                                <div
                                    className={`ms-auto btn-rounded mb-3 cp  ${
                                        selectionModel.length === 0 || (isSending && 'disabled')
                                    }`}
                                    onClick={buyStoreProducts}
                                >
                                    <span>{isSending ? 'Comprando' : 'Comprar'}</span>
                                </div>
                            ) : null}
                        </div>
                        {saleRequestState.labels !== undefined ? (
                            <div className='request-selector'>
                                {saleRequestState.labels.map((label, key) => (
                                    <div
                                        key={key}
                                        className={`item ${
                                            label.label === saleRequestState.requestSelected ? 'active' : ''
                                        } ${label.soldOutCount === 0 ? 'sold-out' : ''} `}
                                        onClick={() => {
                                            setSelectionModel([]);
                                            dispatch(setRequestSelected({ requestSelected: label.label }));
                                            setRequestedAt(label.label);
                                        }}
                                    >
                                        Solicitud de venta {months[new Date(label.label).getMonth()]}{' '}
                                        {moment(label.label).format('Do')} {formatDate(label.label)}{' '}
                                    </div>
                                ))}
                            </div>
                        ) : null}
                    </div>

                    {saleRequestState.requestSelected !== undefined ? (
                        <div className='pb-4'>
                            <DataGrid
                                autoHeight
                                rows={rows}
                                page={page}
                                columns={columns}
                                pagination
                                pageSize={10}
                                rowCount={rowCount}
                                paginationMode='server'
                                onPageChange={handlePageChange}
                                sortingMode='server'
                                sortModel={sortModel}
                                onSortModelChange={handleSortModelChange}
                                loading={isLoading}
                                disableColumnFilter
                                disableColumnMenu
                                disableColumnSelector
                                disableSelectionOnClick
                                hideFooter
                                rowsPerPageOptions={[10]}
                                checkboxSelection={true}
                                onSelectionModelChange={(newSelectionModel) => {
                                    setSelectionModel(newSelectionModel);
                                }}
                                selectionModel={selectionModel}
                                isRowSelectable={(params: GridRowParams) => {
                                    return !params.row.soldOut;
                                }}
                                components={{
                                    Toolbar: Toolbar,
                                    NoRowsOverlay: NoRowsOverlay,
                                }}
                                componentsProps={{
                                    toolbar: {
                                        value: search,
                                        onChange: (evt: ChangeEvent<HTMLInputElement>) => setSearch(evt.target.value),
                                        clearSearch: () => setSearch(''),
                                    },
                                    cell: {
                                        onMouseEnter: handlePopoverOpen,
                                        onMouseLeave: handlePopoverClose,
                                    },
                                }}
                            />
                            {
                                value.trim() !== '' &&
                                <Popover
                                    sx={{
                                        pointerEvents: 'none',
                                    }}
                                    open={open}
                                    anchorEl={anchorEl}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                    onClose={handlePopoverClose}
                                    disableRestoreFocus
                                >
                                    <Typography sx={{ p: 1 }}>{`${value}`}</Typography>
                                </Popover>
                            }
                        </div>
                    ) : (
                        <div className='d-flex my-auto align-items-center justify-content-center ' />
                    )}
                </>
            )}
        </div>
    );
}

export default SaleRequest;
