import React, { MouseEventHandler } from 'react';
import { Link } from 'react-router-dom';

type GradientButtonType = {
    color: string;
    className?: string;
    imgClassName?: string;
    vertical?: boolean;
    src: string;
    title: string;
    subtitle: string;
    to?: string;
    disabled?: boolean;
    onClick?: MouseEventHandler<HTMLButtonElement>;
};

function GradientButton({
    color,
    src,
    title,
    vertical,
    subtitle,
    className,
    imgClassName,
    to,
    disabled = false,
    onClick,
}: GradientButtonType) {
    // const btnRef = useRef<HTMLAnchorElement>(null);

    return to !== undefined ? (
        <Link
            to={to}
            className={`btn-gradient ${vertical ? 'vertical' : 'horizontal'} ${className} ${color} ${
                disabled ? 'disabled' : ''
            } `}
        >
            <div className={`img-container ${imgClassName}`}>
                <img src={require(`../assets/${src}`)} alt='' />
            </div>

            <div className='btn-body'>
                <h5>{title}</h5>
                <p>{subtitle}</p>
            </div>
        </Link>
    ) : (
        <button
            type='button'
            className={`btn-gradient ${vertical ? 'vertical' : 'horizontal'} ${className} ${color} ${
                disabled ? 'disabled' : ''
            } `}
            onClick={onClick !== undefined ? onClick : () => {}}
        >
            <div className={`img-container ${imgClassName}`}>
                <img src={require(`../assets/${src}`)} alt='' />
            </div>

            <div className='btn-body'>
                <h5>{title}</h5>
                <p>{subtitle}</p>
            </div>
        </button>
    );
}

export default GradientButton;
