import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { logout } from './UserSlice';

export interface OrganizationState {
    id: string;
    name: string;
    expirationCriteria: number;
    address: string;
    createdAt: string;
    usersCount: number;
    expireInCount: number;
    expiredCount: number;
    sentToSellCount: number;
    unreadMessageCount?: number;
}

const initialState: OrganizationState = {} as OrganizationState;

const OrganizationSlice = createSlice({
    name: 'organization',
    initialState: initialState,
    reducers: {
        setOrganizationId: (state, { payload }: PayloadAction<Pick<OrganizationState, 'id'>>) => {
            return {
                ...state,
                id: payload.id,
            };
        },
        setOrganization: (state, { payload }: PayloadAction<OrganizationState>) => ({ ...state, ...payload }),
    },
    extraReducers: {
        [logout.type]: () => initialState,
    },
});

export const { setOrganizationId, setOrganization } = OrganizationSlice.actions;
export default OrganizationSlice.reducer;
