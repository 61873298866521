import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserRole } from '../../api/types';

interface UserState {
    isAuth: boolean;
    isAdmin: boolean;
    isModerator: boolean;
    role: UserRole;
    id: string;
    fullName: string;
}

const initialState: UserState = {
    isAuth: false,
    isAdmin: false,
    isModerator: false,
} as UserState;

export const UserSlice = createSlice({
    name: 'user',
    initialState: initialState,
    reducers: {
        setUser: (
            state,
            {
                payload,
            }: PayloadAction<Pick<UserState, 'isAuth' | 'isAdmin' | 'isModerator' | 'id' | 'fullName' | 'role'>>,
        ) => ({ ...state, ...payload }),
        logout: () => initialState,
    },
});

export const { setUser, logout } = UserSlice.actions;

export default UserSlice.reducer;
