import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton, Snackbar as MuiSnackbar, styled } from '@mui/material';
import * as Unicons from '@iconscout/react-unicons';
import { RootState } from '../redux';
import { setAlert } from '../redux/slices/AlertSlice';
import palette from '../scss/palette.module.scss';

const Snackbar = styled(MuiSnackbar)({
    zIndex: 3000,

    '& .MuiSnackbarContent-root': {
        background: palette.background,
        color: palette.gray2,
    },
});

function Alert() {
    const dispatch = useDispatch();

    const alertState = useSelector((state: RootState) => state.alert);
    const [open, setOpen] = React.useState<boolean>(alertState.isActive);

    useEffect(() => {
        setOpen(alertState.isActive);
    }, [alertState.isActive]);

    const handleClose = (event: React.SyntheticEvent<any> | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
        dispatch(setAlert({ isActive: false, message: null }));
    };

    const action = (
        <React.Fragment>
            <IconButton
                size='small'
                aria-label='close'
                color='inherit'
                onClick={() => {
                    dispatch(setAlert({ isActive: false, message: null }));
                }}
            >
                <Unicons.UilTimes />
            </IconButton>
        </React.Fragment>
    );

    return (
        <Snackbar
            open={open}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            autoHideDuration={3000}
            onClose={handleClose}
            message={alertState.message}
            action={action}
        />
    );
}

export default Alert;
