import React, { useState } from 'react';
import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import { UilEllipsisV, UilPen, UilTrashAlt } from '@iconscout/react-unicons';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setAlert } from '../redux/slices/AlertSlice';
import { AxiosResponse } from 'axios';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from './Dialog';

interface Props {
    params: GridRenderCellParams;
    dataCallback: () => void;
    deleteCallback: (id: string) => Promise<AxiosResponse>;
    redirect?: string;
    dialogTitle: string;
    hideable?: boolean;
}

function ActionCell({ params, dataCallback, deleteCallback, redirect, dialogTitle, hideable = false }: Props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    const [isOpen, setOpen] = useState<boolean>(false);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDialogOpen = () => {
        setAnchorEl(null);
        setOpen(true);
    };

    const handleDialogClose = () => {
        setOpen(false);
    };

    const handleUpdate = () => {
        navigate(redirect!);
    };

    const open = Boolean(anchorEl);

    const callback = () => {
        deleteCallback(params.row.id)
            .then((res) => res.data)
            .then((data) => {
                handleDialogClose();
                dispatch(
                    setAlert({
                        isActive: true,
                        message: data.message,
                    }),
                );
                dataCallback();
            })
            .catch((e) => {
                handleDialogClose();
                dispatch(
                    setAlert({
                        isActive: true,
                        message: e.response.data,
                    }),
                );
            });
    };

    const getHideable = (params: GridRenderCellParams) => {
        if (params.row.requestAt !== null) return null;
        else if (params.row.soldOut) return null;
        else
            return (
                <IconButton onClick={handleClick}>
                    <UilEllipsisV />
                </IconButton>
            );
    };

    return (
        <>
            {hideable ? (
                getHideable(params)
            ) : (
                <IconButton onClick={handleClick}>
                    <UilEllipsisV />
                </IconButton>
            )}
            <Menu
                id={params.row.id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                sx={{
                    boxShadow: '0 0 10px rgba(0,0,0,0.1)',
                    '& .MuiMenu-paper': {
                        boxShadow: '0 0 20px rgba(0,0,0,0.05)',
                    },
                }}
            >
                {redirect && (
                    <MenuItem onClick={handleUpdate}>
                        <ListItemIcon>
                            <UilPen />
                        </ListItemIcon>
                        <ListItemText>Editar</ListItemText>
                    </MenuItem>
                )}

                <MenuItem onClick={handleDialogOpen}>
                    <ListItemIcon>
                        <UilTrashAlt />
                    </ListItemIcon>
                    <ListItemText>Eliminar</ListItemText>
                </MenuItem>
            </Menu>
            <Dialog open={isOpen} onClose={handleDialogClose}>
                <DialogTitle>{dialogTitle}</DialogTitle>
                <DialogContent>Esta acción es permanente y no puede ser revertida</DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose}>Cancelar</Button>
                    <Button onClick={callback}>Eliminar</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default ActionCell;
