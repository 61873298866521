import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getMyOrganization } from '../api/api';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../redux';
import { setOrganization } from '../redux/slices/OrganizationSlice';
import GradientButton from '../components/GradientButton';

function DashboardUser() {
    const organizationState = useSelector((state: RootState) => state.organization);

    const dispatch = useDispatch();
    useEffect(() => {
        getMyOrganization()
            .then((res) => res.data)
            .then((data) => {
                dispatch(setOrganization(data.data));
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className='my-3 h-75'>
            <div className='d-flex flex-column flex-lg-row h-100 '>
                <div className='col-12 col-lg-9 col-xxl-10 mb-lg-0 mb-4  d-flex flex-column'>
                    <div className='advise-card'>
                        <h1>
                            Tienes <span>{organizationState.expireInCount}</span>{' '}
                            {organizationState.expireInCount > 1 ? 'productos' : 'producto'} por vencer
                        </h1>
                        <p>Revisa el listado de productos y contactate con pilldomatch</p>
                        <div className='d-flex mt-4'>
                            <Link to='/pronto-vence' className='btn-rounded'>
                                <span>Ver listado</span>
                            </Link>
                        </div>
                    </div>
                </div>

                <div className='flex-fill ps-0 ps-lg-4 d-flex flex-column justify-content-between h-100'>
                    <GradientButton
                        className={'ms-auto mb-4 h-100'}
                        to={'/solicitud-de-venta'}
                        color={'pink'}
                        src={'sale-request.svg'}
                        vertical={true}
                        title={'Solicitudes de ventas'}
                        subtitle={'Solicitudes pendientes de productos pronto vence'}
                    />
                </div>
            </div>
        </div>
    );
}

export default DashboardUser;
