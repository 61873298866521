import { Puff } from '@agney/react-loading';
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { object, string } from 'yup';
import Input from '../components/forms/Input';
import { UilEye } from '@iconscout/react-unicons';
import { getToken, login } from '../api/api';
import jwtDecode from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import { Token } from '../api/types';

function Login() {
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        const token = getToken();
        if (token !== null) {
            const decodedJwt = jwtDecode<Token>(token);
            navigate(
                decodedJwt.role.toLowerCase().trim() === 'admin'
                    ? '/admin'
                    : decodedJwt.role.toLowerCase().trim() === 'moderator'
                    ? '/mod'
                    : '/dashboard',
                { replace: true },
            );
        }
    }, [navigate]);

    return (
        <>
            <div className='login-container'>
                <div className='login-form'>
                    <Formik
                        initialValues={{
                            email: '',
                            password: '',
                        }}
                        validationSchema={object({
                            email: string().email().required(),
                            password: string().required(),
                        })}
                        onSubmit={(values, { setErrors }) => {
                            setLoading(true);
                            login(values)
                                .then((res) => res.data)
                                .then((data) => {
                                    localStorage.setItem('tk', data.accessToken);
                                    //TODO Añadir el type del jwt cuando se defina definitivamente
                                    const decodedJwt = jwtDecode<any>(data.accessToken);
                                    navigate(
                                        decodedJwt.role.toLowerCase().trim() === 'admin'
                                            ? '/admin'
                                            : decodedJwt.role.toLowerCase().trim() === 'moderator'
                                            ? '/mod'
                                            : '/dashboard',
                                        { replace: true },
                                    );
                                })
                                .catch(() => {
                                    setLoading(false);
                                    setErrors({
                                        email: 'Datos Inválidos',
                                        password: 'Datos Inválidos',
                                    });
                                });
                        }}
                    >
                        <Form noValidate>
                            <h4 className='login-message'>
                                Bienvenido a <br />
                                <span className='brand'>Pilldomatch</span>
                            </h4>
                            <Input name='email' label='Email' placeholder='email@example.com' type='email' />
                            <Input
                                name='password'
                                label='Contraseña'
                                placeholder='••••••••••••'
                                type='password'
                                endAdornment={<UilEye />}
                            />

                            <button className='button-primary'>
                                {isLoading && (
                                    //@ts-ignore
                                    <Puff width='20' />
                                )}
                                {isLoading ? 'Ingresando' : 'Ingresar'}
                            </button>
                        </Form>
                    </Formik>
                </div>
            </div>
        </>
    );
}

export default Login;
