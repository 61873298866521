import React, { ChangeEvent, useEffect, useState } from 'react';
import { GridColDef, GridRowModel, GridSortModel } from '@mui/x-data-grid';
import useDebounce from '../utils/useDebounce';
import { deleteUser, getRole, searchMyUsers, searchUser } from '../api/api';
import DataGrid, { NoRowsOverlay, Toolbar } from '../components/DataGrid';
import Divider from '../components/Divider';
import { Link } from 'react-router-dom';
import { UilFocusAdd } from '@iconscout/react-unicons';
import palette from '../scss/palette.module.scss';
import * as Unicons from '@iconscout/react-unicons';
import ActionCell from '../components/ActionCell';
import { useSelector } from 'react-redux';
import { RootState } from '../redux';
import { UserRole } from '../api/types';

function Users() {
    const [page, setPage] = useState<number>(0);
    const [rowCount, setRowCount] = useState<number>(0);
    const [rows, setRows] = useState<Array<GridRowModel>>([]);
    const [sortModel, setSortModel] = useState<GridSortModel>([]);
    const [isLoading, setLoading] = useState<boolean>(false);
    const [search, setSearch] = useState<string>('');
    const debouncedSearch = useDebounce(search, 500);

    const userState = useSelector((state: RootState) => state.user);

    useEffect(() => {
        callback();
        if (debouncedSearch) setPage(0);
        return () => {
            setRows([]);
        };
        // eslint-disable-next-line
    }, [sortModel, page, debouncedSearch]);

    const callback = () => {
        setLoading(true);
        if (userState.role.toLowerCase() === 'admin') {
            searchUser({ q: debouncedSearch, page: page, sort: sortModel })
                .then((res) => res.data)
                .then((data) => {
                    setLoading(false);
                    setRowCount(data.paging.maxItems);
                    setRows(
                        data.content.map((user) => ({
                            id: user.id,
                            firstName: user.firstName,
                            lastName: user.lastName,
                            email: user.email,
                            role: getRole(user.role),
                            organization: user.organization ? user.organization.name : '-',
                        })),
                    );
                })
                .catch(() => {
                    setLoading(false);
                    setRowCount(0);
                    setRows([]);
                });
        } else {
            searchMyUsers({ q: debouncedSearch, page: page, sort: sortModel })
                .then((res) => res.data)
                .then((data) => {
                    setLoading(false);
                    setRowCount(data.paging.maxItems);
                    setRows(
                        data.content.map((user) => ({
                            id: user.id,
                            firstName: user.firstName,
                            lastName: user.lastName,
                            email: user.email,
                            role: getRole(user.role),
                            organization: user.organization ? user.organization.name : '-',
                        })),
                    );
                })
                .catch(() => {
                    setLoading(false);
                    setRowCount(0);
                    setRows([]);
                });
        }
    };

    const handlePageChange = (page: number) => {
        setRows([]);
        setPage(page);
    };

    const handleSortModelChange = (model: GridSortModel) => {
        setSortModel(model);
    };

    const columns: GridColDef[] = [
        { field: 'firstName', headerName: 'Nombre', width: 250 },
        { field: 'lastName', headerName: 'Apellido', width: 250 },
        { field: 'email', headerName: 'Email', width: 250 },
        { field: 'role', headerName: 'Rol', width: 150 },
        { field: 'organization', headerName: 'Farmacia', minWidth: 150, flex: 1 },
        {
            disableColumnMenu: true,
            disableReorder: true,
            sortable: false,
            field: 'Settings',
            headerName: 'Ajustes',
            renderHeader: () => (
                <div style={{ padding: '0 14px' }}>
                    <Unicons.UilSetting color={'#AEB7BD'} />
                </div>
            ),
            renderCell: (params) => (
                <ActionCell
                    params={params}
                    dataCallback={callback}
                    deleteCallback={deleteUser}
                    redirect={`/${userState.role === UserRole.ADMIN ? 'admin' : 'mod'}/usuario/actualizar/${params.id}`}
                    dialogTitle={`¿Eliminar usuario ${params.row.firstName} ${params.row.lastName}?`}
                />
            ),
        },
    ];

    return (
        <>
            <Divider />
            <p>{isLoading && page === 0 ? 'Cargando' : `${rowCount} Usuarios encontrados`}</p>

            <div className='py-3'>
                <DataGrid
                    autoHeight
                    rows={rows}
                    columns={columns}
                    pagination
                    pageSize={10}
                    rowCount={rowCount}
                    paginationMode='server'
                    page={page}
                    onPageChange={handlePageChange}
                    sortingMode='server'
                    sortModel={sortModel}
                    onSortModelChange={handleSortModelChange}
                    loading={isLoading}
                    disableColumnFilter
                    disableColumnSelector
                    disableSelectionOnClick
                    hideFooter
                    rowsPerPageOptions={[10]}
                    components={{
                        Toolbar: Toolbar,
                        NoRowsOverlay: NoRowsOverlay,
                    }}
                    componentsProps={{
                        toolbar: {
                            value: search,
                            onChange: (evt: ChangeEvent<HTMLInputElement>) => setSearch(evt.target.value),
                            clearSearch: () => setSearch(''),
                            button: (
                                <>
                                    <Link
                                        to={`/${userState.role === UserRole.ADMIN ? 'admin' : 'mod'}/crear`}
                                        className='MuiDataGrid-addButton'
                                    >
                                        <UilFocusAdd color={palette.primary} /> Crear Usuario
                                    </Link>
                                </>
                            ),
                        },
                    }}
                />
            </div>
        </>
    );
}

export default Users;
