import React, { useState } from 'react';
import { number, object, string } from 'yup';
import { Form, Formik } from 'formik';
import Input from './Input';
import { Puff } from '@agney/react-loading';
import { createOrganization } from '../../api/api';
import { CreateOrganization as CreateOrganizationType } from '../../api/types';
import { useDispatch } from 'react-redux';
import { setAlert } from '../../redux/slices/AlertSlice';

interface InitialValues {
    name: string;
    expirationCriteria: number | string;
    address: string;
}

const initialValues: InitialValues = {
    name: '',
    expirationCriteria: '',
    address: '',
};

function CreateOrganization() {
    const dispatch = useDispatch();

    const [isLoading, setLoading] = useState<boolean>(false);

    return (
        <div className='d-flex justify-content-center w-100'>
            <div className='w-100'>
                <Formik
                    initialValues={initialValues}
                    validationSchema={object({
                        name: string().required(),
                        expirationCriteria: number()
                            .typeError('Campo Obligatorio')
                            .moreThan(0, 'Debe ser mayor o igual que 1'),
                        address: string().required(),
                    })}
                    onSubmit={(values, { resetForm }) => {
                        setLoading(true);
                        createOrganization(values as CreateOrganizationType)
                            .then((res) => res.data)
                            .then((data) => {
                                resetForm();
                                setLoading(false);
                                dispatch(
                                    setAlert({
                                        isActive: true,
                                        message: data.message,
                                    }),
                                );
                            })
                            .catch((e) => {
                                setLoading(false);
                                dispatch(
                                    setAlert({
                                        isActive: true,
                                        message: e.response.data.message,
                                    }),
                                );
                            });
                    }}
                >
                    <Form autoComplete='off' noValidate>
                        <Input name='name' label='Nombre' placeholder='Ej.: Pilldomatch' type='text' />
                        <Input
                            name='expirationCriteria'
                            label='Criterio de Expiración'
                            placeholder='Ej.: 4'
                            type='number'
                        />
                        <Input name='address' label='Dirección' placeholder='Ej.: Av. Pilldomatch 123' type='text' />
                        <button className='primary-button'>
                            {isLoading && (
                                //@ts-ignore
                                <Puff width={20} />
                            )}
                            {isLoading ? 'Creando Farmacia' : 'Crear Farmacia'}
                        </button>
                    </Form>
                </Formik>
            </div>
        </div>
    );
}

export default CreateOrganization;
