import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { number, object, string } from 'yup';
import Input from './Input';
import Autocomplete, { AutocompleteValue, ListboxComponent } from './Autocomplete';
import useDebounce from '../../utils/useDebounce';
import { addProduct, findProductByName } from '../../api/api';
import { AddProduct as AddProductType } from '../../api/types';
import { useDispatch } from 'react-redux';
import { setAlert } from '../../redux/slices/AlertSlice';
import { Puff } from '@agney/react-loading';

interface InitialValues {
    batch: string;
    expiration: string;
    value: number;
    quantity: number;
    productId:
        | {
              label: string;
              value: string;
          }
        | string
        | null;
}

const initialValues: InitialValues = {
    batch: '',
    expiration: '',
    value: NaN,
    quantity: NaN,
    productId: null,
};

function AddProduct() {
    const dispatch = useDispatch();

    const [isOpen, setOpen] = useState<boolean>(false);
    const [isAutocompleteLoading, setAutocompleteLoading] = useState<boolean>(false);
    const [isLoading, setLoading] = useState<boolean>(false);
    const [products, setProducts] = useState<Array<AutocompleteValue<string, string>>>([]);
    const [search, setSearch] = useState('');
    const debouncedSearch = useDebounce(search, 500);

    useEffect(() => {
        if (isOpen) {
            setProducts([]);
            callback();
        }
        // eslint-disable-next-line
    }, [isOpen, debouncedSearch]);

    const callback = () => {
        setAutocompleteLoading(true);
        findProductByName(search)
            .then((res) => res.data)
            .then((data) => {
                setAutocompleteLoading(false);
                setProducts(
                    data.content.map((product) => ({
                        label: product.name,
                        value: product.id,
                    })),
                );
            })
            .catch(() => {
                setAutocompleteLoading(false);
            });
    };

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <div className='col-6'>
            <Formik
                initialValues={initialValues}
                validationSchema={object({
                    batch: string().required(),
                    expiration: string()
                        .matches(/^((0[1-9])|(1[0-2]))\/([1-9][0-9])$/, 'Formato Incorrecto')
                        .required(),
                    value: number().min(1).required().typeError('Campo Obligatorio'),
                    quantity: number().min(1).required().typeError('Campo Obligatorio'),
                    productId: object({
                        label: string().required(),
                        value: string().required(),
                    }).typeError('Campo Obligatorio'),
                })}
                onSubmit={(values, { resetForm }) => {
                    setLoading(true);
                    const store = { ...values };
                    if (typeof store.productId === 'object' && store.productId !== null)
                        store.productId = store.productId.value;
                    addProduct(store as AddProductType)
                        .then((res) => res.data)
                        .then((data) => {
                            resetForm();
                            setLoading(false);
                            dispatch(
                                setAlert({
                                    isActive: true,
                                    message: data.message,
                                }),
                            );
                        })
                        .catch((e) => {
                            setLoading(false);
                            dispatch(
                                setAlert({
                                    isActive: true,
                                    message: e.response.data.message,
                                }),
                            );
                        });
                }}
            >
                <Form>
                    <Input name='batch' label='Lote' placeholder='Ej.: CYFH2302' type='text' />
                    <Input name='expiration' label='Fecha de Vencimiento' placeholder='Ej.: 02/23' type='text' />
                    <Input name='value' label='Precio' placeholder='Ej.: 2990' type='number' />
                    <Input name='quantity' label='Cantidad' placeholder='Ej.: 100' type='number' />
                    <Autocomplete
                        name='productId'
                        label='Producto'
                        placeholder='Ej.: Tapsin'
                        disablePortal
                        disableListWrap
                        filterOptions={() => products}
                        ListboxComponent={ListboxComponent}
                        onOpen={handleOpen}
                        onClose={handleClose}
                        handleChange={setSearch}
                        open={isOpen}
                        loading={isAutocompleteLoading}
                        options={products}
                        renderGroup={(params) => params}
                        renderOption={(props, option) => [props, option.label]}
                    />
                    <button className='primary-button'>
                        {isLoading && (
                            //@ts-ignore
                            <Puff width={20} />
                        )}
                        {isLoading ? 'Añadiendo Producto' : 'Añadir Producto'}
                    </button>
                </Form>
            </Formik>
        </div>
    );
}

export default AddProduct;
