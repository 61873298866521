import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface SidebarState {
    isActive: boolean;
}

const initialState: SidebarState = {
    isActive: false,
};

const SidebarSlice = createSlice({
    name: 'sidebar',
    initialState: initialState,
    reducers: {
        setActive: (state, { payload }: PayloadAction<SidebarState>) => {
            state.isActive = payload.isActive;
        },
    },
});

export const { setActive } = SidebarSlice.actions;

export default SidebarSlice.reducer;
