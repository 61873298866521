import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { logout } from './UserSlice';

export const months = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
];
export type LabelsSaleRequest = {
    year?: number;
    months?: Array<number>;
};

interface Labels {
    label: string;
    soldOutCount: number;
}

export interface SaleRequestState {
    year?: Array<LabelsSaleRequest>;
    labels?: Array<Labels>;
    months?: Array<number>;
    yearSelected?: number;
    monthSelected?: number;
    requestSelected?: string;
}

const initialState: SaleRequestState = {
    // yearSelected: new Date().getFullYear(),
} as SaleRequestState;

const SaleRequestSlice = createSlice({
    name: 'saleRequest',
    initialState: initialState,
    reducers: {
        setYear: (state, { payload }: PayloadAction<Pick<SaleRequestState, 'year'>>) => {
            return { year: payload.year };
        },
        setMonthsAndYearSelected: (state, { payload }: PayloadAction<Pick<SaleRequestState, 'yearSelected'>>) => {
            let months: any;
            if (state.year !== undefined) {
                months = state.year
                    // eslint-disable-next-line array-callback-return
                    .map((year) => {
                        if (year.year === payload.yearSelected) return year.months;
                    })
                    .flat()
                    .filter((el) => el !== undefined);
            }
            return {
                ...state,
                months: months,
                yearSelected: payload.yearSelected,
                monthSelected: undefined,
                labels: undefined,
                requestSelected: undefined,
            };
        },
        setMonthSelected: (state, { payload }: PayloadAction<Pick<SaleRequestState, 'monthSelected'>>) => {
            return {
                ...state,
                monthSelected: payload.monthSelected,
            };
        },
        setLabels: (state, { payload }: PayloadAction<Pick<SaleRequestState, 'labels'>>) => {
            let labels;
            if (payload.labels) {
                labels = payload.labels.map((label: any) => {
                    return {
                        label: label[0],
                        soldOutCount: label[1],
                    };
                });
            }
            return {
                ...state,
                labels: labels,
            };
        },
        setRequestSelected: (state, { payload }: PayloadAction<Pick<SaleRequestState, 'requestSelected'>>) => {
            return {
                ...state,
                requestSelected: payload.requestSelected,
            };
        },
    },
    extraReducers: {
        [logout.type]: () => initialState,
    },
});

export const { setYear, setMonthsAndYearSelected, setMonthSelected, setLabels, setRequestSelected } =
    SaleRequestSlice.actions;
export default SaleRequestSlice.reducer;
