import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../redux';
import { GridColDef, GridRowModel, GridRowParams, GridSelectionModel, GridSortModel } from '@mui/x-data-grid';
import useDebounce from '../utils/useDebounce';
import { OrganizationState } from '../redux/slices/OrganizationSlice';
import { Link, useParams } from 'react-router-dom';
import { getRoomId, socket } from '../api/socket';
import {
    deleteStoreProduct,
    editStore,
    getAdminChats,
    searchStoreProduct,
    sendChatMessage,
    sendItemsToSell,
} from '../api/api';
import { UserRole } from '../api/types';
import * as Unicons from '@iconscout/react-unicons';
import ActionCell from './ActionCell';
import { setAlert } from '../redux/slices/AlertSlice';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from './Dialog';
import DataGrid, { NoRowsOverlay, Toolbar } from './DataGrid';
import { UilFocusAdd } from '@iconscout/react-unicons';
import palette from '../scss/palette.module.scss';
import Input from './forms/Input';
import { compare } from '../utils/config';
import { formatExpirationDate } from '../utils/format-date';
import { Popover, Typography } from '@mui/material';

function InventoryStock() {
    const userState = useSelector((state: RootState) => state.user);
    const organizationState = useSelector((state: RootState) => state.organization);

    const [page, setPage] = useState<number>(0);
    const [rowCount, setRowCount] = useState<number>(0);
    const [rows, setRows] = useState<Array<GridRowModel>>([]);
    const [sortModel, setSortModel] = useState<GridSortModel>([]);
    const [isLoading, setLoading] = useState<boolean>(false);
    const [search, setSearch] = useState<string>('');
    const debouncedSearch = useDebounce(search, 500);

    const [newSelectionModel, setNewSelectionModel] = useState<GridSelectionModel>([]);

    const [editRowsModel, setEditRowsModel] = useState({});
    const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
    const [selectionRow, setSelectionRow] = useState<any>([]);
    const [organizations, setOrganizations] = useState<Array<Partial<OrganizationState>>>([]);
    const [reload, setReload] = useState<boolean>(false);

    const [isOpen, setOpen] = useState<boolean>(false);

    const dispatch = useDispatch();

    const { organizationId } = useParams();

    useEffect(() => {
        if (organizationState.id !== undefined && organizations.length !== 0) {
            socket.connect();
            socket.emit('chat', {
                room: getRoomId(organizations[0].id!),
            });
        }
        return () => {
            socket.disconnect();
        };
    }, [organizationState, organizations]);

    useEffect(() => {
        callback();
        if (debouncedSearch) setPage(0);
        return () => {
            setRows([]);
        };
        // eslint-disable-next-line
    }, [sortModel, page, debouncedSearch, reload]);


    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    const [value, setValue] = React.useState('');

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setValue(event.currentTarget.innerText);
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const callback = () => {
        setLoading(true);
        searchStoreProduct({
            q: debouncedSearch,
            page: page,
            sort: sortModel,
            organization: organizationId!,
            soldOut: false,
        })
            .then((res) => res.data)
            .then((data) => {
                setLoading(false);
                setRowCount(data.paging.maxItems);
                setRows(
                    data.content.map((store) => {
                        return {
                            ...store,
                            id: store.id,
                            product: store.product.name,
                            expireIn: store.expireIn,
                            expiration: formatExpirationDate(store.expiration),
                            batch: store.batch,
                            value: store.value,
                            quantity: store.quantity,
                            expired: store.expired,
                        };
                    }),
                );
            })
            .catch(() => {
                setLoading(false);
                setRowCount(0);
                setRows([]);
            });
        getAdminChats()
            .then((res) => res.data)
            .then((data) => {
                setOrganizations(data.content);
            })
            .catch(() => {
            });
    };

    const handlePageChange = (page: number) => {
        setRows([]);
        setPage(page);
    };

    const handleSortModelChange = (model: GridSortModel) => {
        setSortModel(model);
    };

    const columns: GridColDef[] = [
        { field: 'product', headerName: 'Producto', flex: 1, minWidth: 250 },
        { field: 'expireIn', headerName: 'Expira en', flex: 1, minWidth: 120, sortable: false },
        { field: 'expiration', headerName: 'Fecha de expiración', flex: 1, minWidth: 200 },
        { field: 'batch', headerName: 'Lote', flex: 1, minWidth: 200 },
        {
            field: 'value',
            headerName: 'Valor',
            flex: 1,
            minWidth: 160,
            editable: userState.role === UserRole.MODERATOR,
            type: 'number',
        },
        {
            field: 'quantity',
            headerName: 'Existencias',
            flex: 1,
            minWidth: 160,
            editable: userState.role === UserRole.MODERATOR,
            type: 'number',
        },
        {
            field: 'state',
            headerName: 'Estado',
            width: 100,
            disableColumnMenu: true,
            disableReorder: true,
            sortable: false,
            renderCell: (params) => (
                <span
                    className={
                        params.row.requestAt !== null && !params.row.soldOut
                            ? 'request-cell'
                            : params.row.soldOut
                                ? 'sold-out-cell'
                                : params.row.expired
                                    ? 'warning-cell'
                                    : ''
                    }
                />
            ),
        },
        {
            disableColumnMenu: true,
            disableReorder: true,
            sortable: false,
            field: 'Settings',
            headerName: 'Ajustes',
            renderHeader: () => (
                <div style={{ padding: '0 14px' }}>
                    <Unicons.UilSetting color={'#AEB7BD'} />
                </div>
            ),
            renderCell: (params) => (
                <ActionCell
                    params={params}
                    dataCallback={callback}
                    deleteCallback={deleteStoreProduct}
                    dialogTitle={`¿Eliminar producto ${params.row.product} del inventario?`}
                    hideable={true}
                />
            ),
            hide: userState.role !== UserRole.MODERATOR,
        },
    ];

    function sendSaleRequest() {
        if (selectionRow.length !== 0) {
            let items = selectionRow.map((item: any) => {
                return {
                    id: item.id,
                    quantity: item.quantity,
                    value: item.value,
                };
            });
            sendItemsToSell(items)
                .then((res) => res.data)
                .then(() => {
                    dispatch(setAlert({ isActive: true, message: 'Solicitud enviada' }));
                    setPage(0);
                    callback();
                    let message = `Se ha generado una solicitud de ${items.length} pronto vence. - Mensaje Automático -`;
                    sendChatMessage(`${organizations[0].id}`, message)
                        .then((res) => res.data)
                        .then((data) => {
                            socket.emit('new_message', {
                                room: getRoomId(organizations[0].id!),
                                ...data.data,
                            });
                            setReload(!reload);
                        })
                        .catch(() => {
                        });
                    setOpen(false);
                })
                .catch(() => {
                    setReload(!reload);
                    setOpen(false);
                    dispatch(setAlert({ isActive: true, message: 'Se ha presentado un error, intentalo mas tarde.' }));
                });
            setReload(!reload);
        }
    }

    const handleEditRowsModelChange = useCallback(
        (model) => {
            if (model[Object.keys(model)[0]] !== undefined) {
                let id = Object.keys(model)[0];
                let rowEdited = rows.find((obj) => obj.id === id);

                if (model[id].quantity !== undefined) {
                    let quantity = model[id].quantity.value;
                    if (quantity < 0) {
                        quantity = 0;
                        model[id].quantity.value = quantity;
                    }
                    if (rowEdited !== undefined) {
                        rowEdited.quantity = quantity;
                    }
                }
                if (model[id].value !== undefined) {
                    let value = model[id].value.value;
                    if (value < 0) {
                        value = 0;
                        model[id].value.value = value;
                    }
                    if (rowEdited !== undefined) {
                        rowEdited.value = value;
                    }
                }
                saveEditedRow(rowEdited);
            }

            setEditRowsModel(model);
        },
        // eslint-disable-next-line
        [rows],
    );

    function saveEditedRow(store: any) {
        editStore(store.id, store)
            .then((res) => res.data)
            .then(() => {
                dispatch(
                    setAlert({
                        isActive: true,
                        message: 'Modificado',
                    }),
                );
            })
            .catch((err) => {
                dispatch(
                    setAlert({
                        isActive: true,
                        message: err.response.data,
                    }),
                );
            });
    }

    const handleDialogOpen = () => {
        setOpen(true);
    };

    const handleDialogClose = () => {
        setReload(!reload);
        setOpen(false);
    };


    return (
        <>
            <div className='d-flex align-items-center'>
                <p>{isLoading && page === 0 ? 'Cargando' : `${rowCount} Productos encontrados`}</p>
                {userState.role !== UserRole.USER && !organizationId && (
                    <>
                        <button
                            type='button'
                            className={`btn-rounded ms-auto ${selectionModel.length === 0 ? 'disabled' : ''}`}
                            onClick={handleDialogOpen}
                        >
                            <span> Enviar solicitud</span>
                        </button>
                        <Dialog open={isOpen} onClose={handleDialogClose}>
                            <DialogTitle>Enviar Solicitud</DialogTitle>
                            <DialogContent>
                                <p>
                                    Recuerda verificar la cantidad de productos existentes antes de generar tu
                                    solicitud.
                                </p>
                                <div className='d-flex flex-column'>
                                    {selectionRow.map((item: any, key: number) => (
                                        <div key={key} className='d-flex align-items-center justify-content-between'>
                                            <div className='col-4'>
                                                <p>{item.product}</p>
                                            </div>
                                            <Input
                                                name='productPrice'
                                                label='Precio a vender'
                                                type='number'
                                                className='me-3'
                                                inputProps={{ min: 1 }}
                                                placeholder='Ej.: 100'
                                                validated={false}
                                                defaultValue={item.value}
                                                onChange={async (
                                                    evt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
                                                ) => {
                                                    item.value = parseInt(evt.target.value);
                                                    let newItem: any = {
                                                        ...item,
                                                        id: item.id,
                                                        index: key,
                                                        value: parseInt(evt.target.value),
                                                    };

                                                    let newItems = newSelectionModel.filter(
                                                        (obj: any) => obj.id !== newItem.id,
                                                    );
                                                    newItems.push(newItem);
                                                    setNewSelectionModel(
                                                        newItems.sort((a: any, b: any) => compare(a, b, 'index')),
                                                    );
                                                }}
                                            />
                                            <Input
                                                name='productQuantity'
                                                label='Cantidad'
                                                type='number'
                                                inputProps={{ min: 1 }}
                                                placeholder='Ej.: 100'
                                                validated={false}
                                                defaultValue={item.quantity}
                                                onChange={(
                                                    evt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
                                                ) => {
                                                    let newItem: any = item;
                                                    item.quantity = parseInt(evt.target.value);
                                                    newItem = {
                                                        ...item,
                                                        id: item.id,
                                                        index: key,
                                                        quantity: parseInt(evt.target.value),
                                                    };
                                                    let newItems = newSelectionModel.filter(
                                                        (obj: any) => obj.id !== newItem.id,
                                                    );
                                                    newItems.push(newItem);
                                                    setNewSelectionModel(
                                                        newItems.sort((a: any, b: any) => compare(a, b, 'index')),
                                                    );
                                                }}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleDialogClose}>Cancelar</Button>
                                <Button onClick={sendSaleRequest}>Enviar solicitud</Button>
                            </DialogActions>
                        </Dialog>
                    </>
                )}
            </div>

            <div className='py-3'>
                <DataGrid
                    autoHeight
                    rows={rows}
                    page={page}
                    columns={columns}
                    pagination
                    pageSize={10}
                    rowCount={rowCount}
                    paginationMode='server'
                    onPageChange={handlePageChange}
                    sortingMode='server'
                    sortModel={sortModel}
                    onSortModelChange={handleSortModelChange}
                    loading={isLoading}
                    disableColumnFilter
                    disableColumnMenu
                    disableColumnSelector
                    disableSelectionOnClick


                    hideFooter
                    rowsPerPageOptions={[10]}
                    checkboxSelection
                    onSelectionModelChange={(newSelectionModel) => {
                        setSelectionModel(newSelectionModel);
                        const selectedIDs = new Set(newSelectionModel);
                        const selectedRowData = rows.filter((row) => selectedIDs.has(row.id.toString()));
                        setSelectionRow(selectedRowData);
                    }}
                    editRowsModel={editRowsModel}
                    onEditRowsModelChange={handleEditRowsModelChange}
                    isCellEditable={(params) => {
                        return params.row.requestAt === null;
                    }}
                    isRowSelectable={(params: GridRowParams) => {
                        if (params.row.requestAt !== null) return false;
                        return !params.row.soldOut;
                    }}
                    components={{
                        Toolbar: Toolbar,
                        NoRowsOverlay: NoRowsOverlay,
                    }}
                    componentsProps={{
                        toolbar: {
                            value: search,
                            onChange: (evt: ChangeEvent<HTMLInputElement>) => setSearch(evt.target.value),
                            clearSearch: () => setSearch(''),
                            expiredHint: true,
                            button:
                                userState.role === UserRole.MODERATOR ? (
                                    <>
                                        <Link to='/mod/inventario/agregar' className='MuiDataGrid-addButton'>
                                            <UilFocusAdd color={palette.primary} /> Añadir Producto
                                        </Link>
                                    </>
                                ) : null,
                        },
                        cell: {
                            onMouseEnter: handlePopoverOpen,
                            onMouseLeave: handlePopoverClose,
                        },
                    }}
                />
                {
                    value.trim() !== '' &&
                    <Popover
                        sx={{
                            pointerEvents: 'none',
                        }}
                        open={open}
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        onClose={handlePopoverClose}
                        disableRestoreFocus
                    >
                        <Typography sx={{ p: 1 }}>{`${value}`}</Typography>
                    </Popover>
                }

            </div>
        </>
    );
}

export default InventoryStock;
