import React from 'react';
import { Bars } from '@agney/react-loading';
import palette from '../scss/palette.module.scss';

function LoadingScreen() {
    return (
        <div className='vh-100 vw-100 d-flex'>
            <div className='m-auto text-white text-center'>
                {/*@ts-ignore*/}
                <Bars width='100' color={palette.gray2} />
            </div>
        </div>
    );
}

export default LoadingScreen;
