import React, { useRef } from 'react';
import { FieldHookConfig, useField } from 'formik';
import {
    Autocomplete as MuiAutocomplete,
    AutocompleteProps,
    CircularProgress,
    ListSubheader,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Input from './Input';
import { UilAngleDown, UilExclamationTriangle } from '@iconscout/react-unicons';
import palette from '../../scss/palette.module.scss';
import { ListChildComponentProps, VariableSizeList } from 'react-window';

export interface AutocompleteValue<T, S> {
    label: T;
    value: S;
}

const CustomAutocomplete: React.FC<AutocompleteProps<any, any, any, any>> = styled(MuiAutocomplete)({
    '& .MuiInputBase-container': {
        margin: 0,
    },

    '& + .MuiAutocomplete-popper .MuiAutocomplete-paper': {
        boxShadow: palette.boxShadow,
    },

    '& + .MuiAutocomplete-feedback': {
        display: 'flex',
        alignItems: 'center',
        color: palette.error,
        fontSize: '0.875rem',
        marginTop: '0.5rem',

        svg: {
            marginRight: '0.25rem',
            width: '1rem',
            height: '1rem',
        },
    },

    '& .MuiInput-endAdornment .Mui-disabled': {
        color: 'rgba(0, 0, 0, 0.26)',
        svg: {
            color: 'rgba(0, 0, 0, 0.26)',
        },
    },
});

type Props<
    T,
    Multiple extends boolean | undefined,
    DisableClearable extends boolean | undefined,
    FreeSolo extends boolean | undefined,
> = {
    validated?: boolean;
    label?: string;
    handleChange?: (value: any) => void;
} & Omit<AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>, 'renderInput'>;

type PropsValidated<
    T,
    Multiple extends boolean | undefined,
    DisableClearable extends boolean | undefined,
    FreeSolo extends boolean | undefined,
> = Props<T, Multiple, DisableClearable, FreeSolo> & FieldHookConfig<any>;

const AutocompleteUnvalidated = React.forwardRef(
    ({ label, handleChange, ...props }: Props<any, any, any, any>, ref: React.ForwardedRef<HTMLDivElement>) => {
        return (
            <div>
                {label && <label>{label}</label>}

                <CustomAutocomplete
                    {...props}
                    popupIcon={<UilAngleDown />}
                    renderInput={(params) => (
                        <Input
                            name={params.id}
                            validated={false}
                            ref={params.InputProps.ref}
                            placeholder={props.placeholder}
                            className={params.InputProps.className}
                            startAdornment={params.InputProps.startAdornment}
                            endAdornment={
                                <>
                                    {props.loading ? <CircularProgress color='inherit' size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </>
                            }
                            inputProps={params.inputProps}
                            onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                                if (handleChange) handleChange(evt.target.value);
                            }}
                            disabled={params.disabled}
                        />
                    )}
                    ref={ref}
                />
            </div>
        );
    },
);

const AutocompleteValidated = React.forwardRef(
    (
        { label, handleChange, ...props }: PropsValidated<any, any, any, any>,
        ref: React.ForwardedRef<HTMLDivElement>,
    ) => {
        const [field, meta, helpers] = useField(props);

        return (
            <div className='MuiAutocomplete-container'>
                {label && <label>{label}</label>}
                <CustomAutocomplete
                    {...field}
                    {...props}
                    popupIcon={<UilAngleDown />}
                    onChange={(evt, value) => {
                        helpers.setValue(value);
                    }}
                    onInputChange={(evt, value) => {
                        if (handleChange) handleChange(value);
                    }}
                    renderInput={(params) => (
                        <Input
                            name={params.id}
                            validated={false}
                            ref={params.InputProps.ref}
                            placeholder={props.placeholder}
                            className={params.InputProps.className}
                            startAdornment={params.InputProps.startAdornment}
                            endAdornment={
                                <>
                                    {props.loading ? <CircularProgress color='inherit' size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </>
                            }
                            inputProps={params.inputProps}
                            disabled={params.disabled}

                            /*onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                                if (handleChange) handleChange(evt.target.value);
                            }}*/
                        />
                    )}
                    ref={ref}
                />
                {meta.touched && meta.error && (
                    <span className='MuiAutocomplete-feedback'>
                        <UilExclamationTriangle />
                        {meta.error}
                    </span>
                )}
            </div>
        );
    },
);

const Autocomplete = React.forwardRef(
    (
        { validated = true, ...props }: Props<any, any, any, any> | PropsValidated<any, any, any, any>,
        ref: React.ForwardedRef<HTMLDivElement>,
    ) => {
        const autocompleteRef = useRef<HTMLDivElement>(null);
        return validated ? (
            // @ts-ignore
            <AutocompleteValidated {...props} ref={ref ? ref : autocompleteRef} />
        ) : (
            <AutocompleteUnvalidated {...props} ref={ref ? ref : autocompleteRef} />
        );
    },
);

function renderRow(props: ListChildComponentProps) {
    const { data, index, style } = props;
    const dataSet = data[index];
    const inlineStyle = {
        ...style,
        top: (style.top as number) + 8,
    };

    if (dataSet.hasOwnProperty('group')) {
        return (
            <ListSubheader key={dataSet.key} component='div' style={inlineStyle}>
                {dataSet.group}
            </ListSubheader>
        );
    }

    return (
        <Typography component='li' {...dataSet[0]} noWrap style={inlineStyle}>
            {dataSet[1]}
        </Typography>
    );
}

function useResetCache(data: any) {
    const ref = React.useRef<VariableSizeList>(null);
    React.useEffect(() => {
        if (ref.current != null) {
            ref.current.resetAfterIndex(0, true);
        }
    }, [data]);
    return ref;
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
    const outerProps = React.useContext(OuterElementContext);
    return <div ref={ref} {...props} {...outerProps} />;
});

export const ListboxComponent = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLElement>>(
    function ListboxComponent(props, ref) {
        const { children, ...other } = props;
        const itemData: React.ReactChild[] = [];
        (children as React.ReactChild[]).forEach((item: React.ReactChild & { children?: React.ReactChild[] }) => {
            itemData.push(item);
            itemData.push(...(item.children || []));
        });

        const theme = useTheme();
        const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
            noSsr: true,
        });
        const itemCount = itemData.length;
        const itemSize = smUp ? 36 : 48;

        const getChildSize = (child: React.ReactChild) => {
            if (child.hasOwnProperty('group')) {
                return 48;
            }

            return itemSize;
        };

        const getHeight = () => {
            if (itemCount > 8) {
                return 8 * itemSize;
            }
            return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
        };

        const gridRef = useResetCache(itemCount);

        return (
            <div ref={ref}>
                <OuterElementContext.Provider value={other}>
                    <VariableSizeList
                        itemData={itemData}
                        height={getHeight() + 2 * 8}
                        width='100%'
                        ref={gridRef}
                        outerElementType={OuterElementType}
                        innerElementType='ul'
                        itemSize={(index) => getChildSize(itemData[index])}
                        overscanCount={5}
                        itemCount={itemCount}
                    >
                        {renderRow}
                    </VariableSizeList>
                </OuterElementContext.Provider>
            </div>
        );
    },
);

export default Autocomplete;
