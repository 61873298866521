import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { logout } from './UserSlice';

interface Alert {
    isActive: boolean;
    message: string | null;
}

const initialState = {} as Alert;

export const AlertSlice = createSlice({
    name: 'alert',
    initialState: initialState,
    reducers: {
        setAlert: (state, { payload }: PayloadAction<Alert>) => ({
            ...state,
            ...payload,
        }),
    },
    extraReducers: {
        [logout.type]: () => initialState,
    },
});

export const { setAlert } = AlertSlice.actions;

export default AlertSlice.reducer;
