import React, { useEffect, useState } from 'react';
import Autocomplete, { AutocompleteValue, ListboxComponent } from './Autocomplete';
import useDebounce from '../../utils/useDebounce';
import { findAllProducts, sendMessageToGroup } from '../../api/api';
import { useDispatch } from 'react-redux';
import { setAlert } from '../../redux/slices/AlertSlice';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import Input from './Input';

function ProductSearchChat() {
    const [isAutocompleteLoading, setAutocompleteLoading] = useState<boolean>(false);

    const [searchProd, setSearchProd] = useState('');
    const [products] = useState([]);
    const [isProductOpen, setProductOpen] = useState<boolean>(false);
    const [productsAutoComplete, setProductsAutoComplete] = useState<Array<AutocompleteValue<string, string>>>([]);
    const [productValue, setProductValue] = React.useState<string | null>(null);
    const debouncedSearchProd = useDebounce(searchProd, 500);
    const [quantity, setQuantity] = useState<number | undefined>(undefined);
    const [price, setPrice] = useState<number | undefined>(undefined);
    const [filter, setFilter] = useState(true);
    const [sending, setSending] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        if (isProductOpen) {
            setProductsAutoComplete([]);
            productCallback();
        }
        // eslint-disable-next-line
    }, [isProductOpen, debouncedSearchProd]);

    const productCallback = () => {
        setAutocompleteLoading(true);
        findAllProducts(searchProd)
            .then((res) => res.data)
            .then((data) => {
                setAutocompleteLoading(false);
                setProductsAutoComplete(
                    data.content.map((name: string) => ({
                        label: name,
                        value: name,
                    })),
                );
            })
            .catch(() => {
                setAutocompleteLoading(false);
            });
    };

    const sendMessage = () => {
        setSending(true);
        const message = {
            message: `Hola!, estamos en búsqueda de ${quantity} ${productValue}${
                price ? ` a un precio de ${price} por unidad` : ''
            }.`,
            quantity,
            q: `${productValue}`,
        };

        sendMessageToGroup(message, filter)
            .then((res) => res.data)
            .then(() => {
                dispatch(
                    setAlert({
                        message: 'Enviado con exito.',
                        isActive: true,
                    }),
                );
                setSending(false);
            })
            .catch((err) => {
                dispatch(
                    setAlert({
                        message: err.response.data,
                        isActive: true,
                    }),
                );
                setSending(false);
            });
    };
    return (
        <div className='d-flex flex-column flex-lg-row mb-3 align-items-center justify-content-between w-100 label-size-small'>
            <div className='col-12 col-lg-4 '>
                <Autocomplete
                    freeSolo
                    label='Producto *'
                    name='Productos'
                    placeholder='Ej.: Tapsin'
                    disablePortal
                    disableListWrap
                    filterOptions={() => productsAutoComplete}
                    ListboxComponent={ListboxComponent}
                    onOpen={() => setProductOpen(true)}
                    onClose={() => setProductOpen(false)}
                    handleChange={setSearchProd}
                    open={isProductOpen}
                    loading={isAutocompleteLoading}
                    options={products}
                    renderGroup={(params) => params}
                    renderOption={(props, option) => [props, option.label]}
                    validated={false}
                    isOptionEqualToValue={() => {
                        return true;
                    }}
                    value={productValue}
                    onChange={(event, newValue) => {
                        if (newValue !== null) {
                            if (newValue.value !== undefined) {
                                setProductValue(newValue.value);
                            }
                        } else {
                            setProductValue('');
                        }
                    }}
                    onBlur={(event: any) => {
                        if (event.target.value.trim() !== '') {
                            setProductValue(event.target.value);
                        }
                    }}
                />
            </div>
            <div className='col-12 col-lg-2 mx-3'>
                <Input
                    name='productQuantity'
                    label='Cantidad *'
                    type='number'
                    inputProps={{ min: 0 }}
                    placeholder='Ej.: 0'
                    validated={false}
                    value={quantity}
                    onChange={(evt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
                        setQuantity(parseInt(evt.target.value))
                    }
                />
            </div>
            <div className='col-12 col-lg-1 mx-3'>
                <Input
                    name='productPrice'
                    label='Precio'
                    type='number'
                    inputProps={{ min: 1 }}
                    placeholder='Ej.: 100'
                    validated={false}
                    value={price}
                    onChange={(evt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
                        setPrice(parseInt(evt.target.value))
                    }
                />
            </div>

            <div className='flex-fill d-flex'>
                <FormGroup>
                    <FormControlLabel
                        control={<Checkbox checked={filter} onClick={() => setFilter(!filter)} />}
                        label='Filtrar por quienes tienen el producto'
                    />
                </FormGroup>
                <button
                    className={`btn-rounded ms-auto ${
                        quantity && quantity > 0 && productValue !== null && productValue?.trim() !== '' && !sending
                            ? ''
                            : 'disabled'
                    }`}
                    onClick={sendMessage}
                >
                    <span>{sending ? 'Enviando' : 'Enviar'}</span>
                </button>
            </div>
        </div>
    );
}

export default ProductSearchChat;
