import { configureStore } from '@reduxjs/toolkit';
import AlertSlice from './slices/AlertSlice';
import OrganizationSlice from './slices/OrganizationSlice';
import UserSlice from './slices/UserSlice';
import SaleRequestSlice from './slices/SaleRequestSlice';
import SidebarSlice from './slices/SidebarSlice';

const { NODE_ENV } = process.env;

export const store = configureStore({
    reducer: {
        user: UserSlice,
        organization: OrganizationSlice,
        alert: AlertSlice,
        saleRequest: SaleRequestSlice,
        sidebar: SidebarSlice,
    },
    devTools: NODE_ENV !== 'production',
});

export type RootState = ReturnType<typeof store.getState>;
