import React, { useEffect, useState } from 'react';
import { number, object, string } from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { findOrganization, updateOrganization } from '../api/api';
import { setAlert } from '../redux/slices/AlertSlice';
import { Form, Formik } from 'formik';
import { UpdateOrganization as UpdateOrganizationInterface } from '../api/types';
import Input from '../components/forms/Input';
import { Puff } from '@agney/react-loading';

interface InitialValues {
    name: string;
    expirationCriteria: number;
    address: string;
}

function UpdateOrganization() {
    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [isLoading, setLoading] = useState<boolean>(false);
    const [organization, setOrganization] = useState<InitialValues | null>(null);

    useEffect(() => {
        callback();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const callback = () => {
        findOrganization(id!)
            .then((res) => res.data)
            .then((data) => {
                const organization = data.data;
                setOrganization({
                    name: organization.name,
                    address: organization.address,
                    expirationCriteria: organization.expirationCriteria,
                });
            })
            .catch((err) => {
                dispatch(setAlert({ isActive: true, message: err.response.data }));
            });
    };

    return (
        <>
            <div className='d-flex justify-content-center'>
                {organization && (
                    <div className='col-12 col-xl-8 col-xxl-6'>
                        <Formik
                            initialValues={organization}
                            validationSchema={object({
                                name: string().required(),
                                expirationCriteria: number()
                                    .typeError('Campo Obligatorio')
                                    .moreThan(0, 'Debe ser mayor o igual que 1'),
                                address: string().required(),
                            })}
                            onSubmit={(values) => {
                                setLoading(true);
                                const updated = Object.fromEntries(
                                    // @ts-ignore
                                    Object.entries(values).filter(([key, value]) => value !== organization[key]),
                                );
                                updateOrganization(id!, updated as UpdateOrganizationInterface)
                                    .then((res) => res.data)
                                    .then((data) => {
                                        setLoading(false);
                                        dispatch(
                                            setAlert({
                                                isActive: true,
                                                message: data.message,
                                            }),
                                        );
                                        setTimeout(() => {
                                            navigate('/admin/organizaciones');
                                        }, 3000);
                                    })
                                    .catch((err) => {
                                        setLoading(false);
                                        dispatch(setAlert({ isActive: true, message: err.response.data }));
                                    });
                            }}
                        >
                            <Form autoComplete='off' noValidate>
                                <Input name='name' label='Nombre' placeholder='Ej.: Pilldomatch' type='text' />
                                <Input
                                    name='expirationCriteria'
                                    label='Criterio de Expiración'
                                    placeholder='Ej.: 4'
                                    type='number'
                                />
                                <Input
                                    name='address'
                                    label='Dirección'
                                    placeholder='Ej.: Av. Pilldomatch 123'
                                    type='text'
                                />
                                <button className='primary-button'>
                                    {isLoading && (
                                        //@ts-ignore
                                        <Puff width={20} />
                                    )}
                                    {isLoading ? 'Actualizando Farmacia' : 'Actualizar Farmacia'}
                                </button>
                            </Form>
                        </Formik>
                    </div>
                )}
            </div>
        </>
    );
}

export default UpdateOrganization;
