import axios from 'axios';
import { store } from '../redux';
import { logout } from '../redux/slices/UserSlice';
import { GridRowId, GridSortModel } from '@mui/x-data-grid';
import {
    AccessToken,
    AddProduct,
    Contents,
    CreateOrganization,
    CreateProduct,
    CreateUser,
    Data,
    LoginUser,
    Message,
    Organization,
    Product,
    Search,
    SearchStore,
    Store,
    UpdateOrganization,
    UpdateUser,
    User,
    UserRole,
} from './types';

const url = process.env.REACT_APP_API_URL;

export const getToken = () => localStorage.getItem('tk');

const getTokenHeaders = () => ({ Authorization: `Bearer ${getToken()}` });

export const setLogout = () => {
    localStorage.clear();
    store.dispatch(logout());
};

export const getRole = (role: UserRole) => {
    const roles = {
        admin: 'Admin',
        moderator: 'Moderador',
        user: 'Usuario',
    };
    return roles[role];
};

export const login = ({ email, password }: LoginUser) => {
    return axios.post<AccessToken>(`${url}/auth`, { email, password });
};

export const auth = () => {
    const headers = getTokenHeaders();
    return axios.get(`${url}/auth`, {
        headers,
    });
};

export function searchOrg({ q, page, limit = 10, sort, bySaleRequest = false }: Search) {
    const headers = getTokenHeaders();
    const params = new URLSearchParams();
    params.append('page', (page + 1).toString());
    params.append('limit', limit.toString());
    params.append('bySaleRequest', `${bySaleRequest}`);
    if (sort.length !== 0 && sort[0].sort !== null && sort[0].sort !== undefined) {
        params.append('sortBy', sort[0].field);
        params.append('orderBy', sort[0].sort);
    }
    if (q && q !== '') params.append('q', q);
    return axios.get<Contents<Organization>>(`${url}/organization/search`, {
        headers,
        params,
    });
}

export function searchUser({ q, page, limit = 10, sort }: Search) {
    const headers = getTokenHeaders();
    const params = new URLSearchParams();
    params.append('page', (page + 1).toString());
    params.append('limit', limit.toString());
    if (sort.length !== 0 && sort[0].sort !== null && sort[0].sort !== undefined) {
        params.append('sortBy', sort[0].field);
        params.append('orderBy', sort[0].sort);
    }
    if (q && q !== '') params.append('q', q);
    return axios.get<Contents<User>>(`${url}/users/search`, {
        headers,
        params,
    });
}

export function searchMyUsers({ q, page, limit = 10, sort }: Search) {
    const headers = getTokenHeaders();
    const params = new URLSearchParams();
    params.append('page', (page + 1).toString());
    params.append('limit', limit.toString());
    if (sort.length !== 0 && sort[0].sort !== null && sort[0].sort !== undefined) {
        params.append('sortBy', sort[0].field);
        params.append('orderBy', sort[0].sort);
    }
    if (q && q !== '') params.append('q', q);
    return axios.get<Contents<User>>(`${url}/users/search-by-organization`, {
        headers,
        params,
    });
}

export function getMyOrganization() {
    const headers = getTokenHeaders();
    return axios.get(`${url}/organization`, {
        headers,
    });
}

export function searchStoreProduct({ q, page, limit = 10, sort, organization, soldOut }: SearchStore) {
    const headers = getTokenHeaders();
    const params = new URLSearchParams();
    params.append('page', (page + 1).toString());
    params.append('limit', limit.toString());
    params.append('soldOut', soldOut.toString());
    if (organization) params.append('org-id', organization);
    if (sort.length !== 0 && sort[0].sort !== null && sort[0].sort !== undefined) {
        params.append('sortBy', sort[0].field);
        params.append('orderBy', sort[0].sort);
    }
    if (q && q !== '') params.append('q', q);
    return axios.get<Contents<Store>>(`${url}/store/search`, {
        headers,
        params,
    });
}

export function getMyStoreProductsExpiresIn({ page, limit = 10, sort }: Omit<Search, 'q'>) {
    const headers = getTokenHeaders();
    const params = new URLSearchParams();
    params.append('page', (page + 1).toString());
    params.append('limit', limit.toString());
    if (sort.length !== 0 && sort[0].sort !== null && sort[0].sort !== undefined) {
        params.append('sortBy', sort[0].field);
        params.append('orderBy', sort[0].sort);
    }
    return axios.get<Contents<Store>>(`${url}/store/expire-in`, {
        headers,
        params,
    });
}

export function Last5Organizations() {
    const headers = getTokenHeaders();
    return axios.get(`${url}/organization/last`, {
        headers,
    });
}

export function findOrganization(id: String) {
    const headers = getTokenHeaders();
    return axios.get(`${url}/organization/find?id=${id}`, {
        headers,
    });
}

export function getChatMessages(id: string, page: number) {
    const headers = getTokenHeaders();
    return axios.get(`${url}/chat?destinationId=${id}&page=${page}`, {
        headers,
    });
}

export function getCurrentChats(limit: number) {
    const headers = getTokenHeaders();
    return axios.get(`${url}/chat/current-chats?page=1&limit=${limit}`, {
        headers,
    });
}

export function getAdminChats() {
    const headers = getTokenHeaders();
    return axios.get(`${url}/chat/admin-chat`, {
        headers,
    });
}

export function readMessages(id: string) {
    const headers = getTokenHeaders();
    return axios.post(
        `${url}/chat/see?destinationId=${id}`,
        {},
        {
            headers,
        },
    );
}

export function sendChatMessage(id: string, message: string) {
    const headers = getTokenHeaders();
    return axios.post(
        `${url}/chat`,
        {
            message,
            destinationId: id,
        },
        {
            headers,
        },
    );
}

export function findOrganizationByName(q: string) {
    const headers = getTokenHeaders();
    const params = new URLSearchParams();
    if (q && q !== '') params.append('q', q);
    return axios.get<Omit<Contents<Pick<Organization, 'id' | 'name'>>, 'paging'>>(`${url}/organization/find-by-name`, {
        headers,
        params,
    });
}

export function createUser(user: CreateUser) {
    const headers = getTokenHeaders();
    return axios.post<Message>(
        `${url}/users`,
        {
            ...user,
        },
        {
            headers,
        },
    );
}

export function getSaleRequestLabelsDates(id: string) {
    const headers = getTokenHeaders();
    return axios.get(`${url}/store/send-to-sell/dates?id=${id}`, {
        headers,
    });
}

export function getLabelsOfMonthSelected(id: string, year: number, month: number) {
    const headers = getTokenHeaders();
    return axios.get(`${url}/store/send-to-sell/labels?id=${id}&year=${year}&month=${month}&page=1`, {
        headers,
    });
}

export function getStoreRequestSelected(id: string, requestedAt: string, page: number, q: string, sort: GridSortModel) {
    const headers = getTokenHeaders();
    const params = new URLSearchParams();
    params.append('page', (page + 1).toString());
    params.append('requestedAt', requestedAt);
    params.append('id', id);
    if (sort.length !== 0 && sort[0].sort !== null && sort[0].sort !== undefined) {
        params.append('sortBy', sort[0].field);
        params.append('orderBy', sort[0].sort);
    }
    if (q && q !== '') params.append('q', q);

    // return axios.get(`${url}/store/send-to-sell?id=${id}&requestedAt=${requestedAt}&page=${(page + 1).toString()}&q=${q}`, {
    return axios.get(`${url}/store/send-to-sell`, {
        headers,
        params,
    });
}

export function createOrganization(organization: CreateOrganization) {
    const headers = getTokenHeaders();
    return axios.post<Message>(
        `${url}/organization`,
        {
            ...organization,
        },
        {
            headers,
        },
    );
}

export function sendItemsToSell(items: any) {
    const headers = getTokenHeaders();
    return axios.post(`${url}/store/send-to-sell`, items, {
        headers,
    });
}

export function markStoreAsSold(ids: GridRowId[], cancel: boolean = false) {
    const headers = getTokenHeaders();
    return axios.patch(`${url}/store/mark-as-sold?cancel=${cancel}`, ids, {
        headers,
    });
}

export function editStore(id: string, store: any) {
    const headers = getTokenHeaders();
    return axios.patch(`${url}/store?id=${id}`, store, {
        headers,
    });
}

export function deleteUser(id: string) {
    const headers = getTokenHeaders();
    const params = new URLSearchParams();
    params.append('id', id);
    return axios.delete<Message>(`${url}/users`, {
        headers,
        params,
    });
}

export function findProductByName(q: string) {
    const headers = getTokenHeaders();
    const params = new URLSearchParams();
    if (q && q !== '') params.append('q', q);
    return axios.get<Omit<Contents<Pick<Product, 'id' | 'name'>>, 'paging'>>(`${url}/product/find-by-name`, {
        headers,
        params,
    });
}

export function addProduct(data: AddProduct) {
    const headers = getTokenHeaders();

    return axios.post<Message>(
        `${url}/store`,
        {
            ...data,
        },
        {
            headers,
        },
    );
}

export function createProduct(data: CreateProduct) {
    const headers = getTokenHeaders();

    return axios.post<Message>(
        `${url}/product`,
        {
            ...data,
        },
        {
            headers,
        },
    );
}

export function findAllProducts(q: string) {
    const headers = getTokenHeaders();
    const params = new URLSearchParams();
    if (q && q !== '') params.append('q', q);
    return axios.get(`${url}/product/search-all`, {
        headers,
        params,
    });
}

export function sendMessageToGroup(message: any, filter: boolean) {
    const headers = getTokenHeaders();
    return axios.post(`${url}/chat/send-to-group${filter ? '/filter' : ''}`, message, {
        headers,
    });
}

export function getUserDetails(id?: string) {
    const headers = getTokenHeaders();
    const params = new URLSearchParams();
    if (id) params.append('id', id);
    return axios.get<Data<User>>(`${url}/users/details`, { headers, params });
}

export function updateUser(id: string, data: UpdateUser) {
    const headers = getTokenHeaders();
    const params = new URLSearchParams();
    params.append('id', id);
    return axios.patch<Message>(
        `${url}/users/update`,
        {
            ...data,
        },
        {
            headers,
            params,
        },
    );
}

export function deleteOrganization(id: string) {
    const headers = getTokenHeaders();
    const params = new URLSearchParams();
    params.append('id', id);
    return axios.delete<Message>(`${url}/organization`, {
        headers,
        params,
    });
}

export function updateOrganization(id: string, data: UpdateOrganization) {
    const headers = getTokenHeaders();
    const params = new URLSearchParams();
    params.append('id', id);
    return axios.patch<Message>(
        `${url}/organization`,
        {
            ...data,
        },
        {
            headers,
            params,
        },
    );
}

export function deleteStoreProduct(id: string) {
    const headers = getTokenHeaders();
    const params = new URLSearchParams();
    params.append('id', id);
    return axios.delete<Message>(`${url}/store`, {
        headers,
        params,
    });
}
