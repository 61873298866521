import React, { useCallback, useEffect, useState } from 'react';
import { getAdminChats, getMyStoreProductsExpiresIn, sendChatMessage, sendItemsToSell } from '../api/api';
import { GridColDef, GridRowModel, GridSelectionModel, GridSortModel } from '@mui/x-data-grid';
import DataGrid, { NoRowsOverlay, Toolbar } from '../components/DataGrid';
import ProductActionCell from '../components/ProductActionCell';
import Divider from '../components/Divider';
import * as Unicons from '@iconscout/react-unicons';
import { useDispatch, useSelector } from 'react-redux';
import { setAlert } from '../redux/slices/AlertSlice';
import GradientButton from '../components/GradientButton';
import { OrganizationState } from '../redux/slices/OrganizationSlice';
import { getRoomId, socket } from '../api/socket';
import { RootState } from '../redux';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '../components/Dialog';
import { UserRole } from '../api/types';
import Input from '../components/forms/Input';
import { compare } from '../utils/config';
import { formatExpirationDate } from '../utils/format-date';
import { Popover, Typography } from '@mui/material';

function Products() {
    const [reload, setReload] = useState<boolean>(false);

    const columns: GridColDef[] = [
        { field: 'product', headerName: 'Producto', minWidth: 200, flex: 1, editable: false },
        { field: 'expireIn', headerName: 'Expira en (meses)', flex: 1, minWidth: 120, editable: false },
        { field: 'expiration', headerName: 'Fecha de expiración', flex: 1, minWidth: 200, editable: false },
        { field: 'batch', headerName: 'Lote', flex: 1, minWidth: 200, editable: false },
        { field: 'value', headerName: 'Valor', flex: 1, minWidth: 160, editable: false },
        { field: 'quantity', headerName: 'Existencias', flex: 1, minWidth: 160, editable: true, type: 'number' },
        {
            disableColumnMenu: true,
            disableReorder: true,
            sortable: false,
            field: 'Settings',
            headerName: 'Ajustes',
            renderHeader: () => (
                <div style={{ padding: '0 14px' }}>
                    <Unicons.UilSetting color={'#AEB7BD'} />
                </div>
            ),
            renderCell: (params) => <ProductActionCell params={params} />,
            hide: true,
        },
        {
            field: 'state',
            headerName: 'Estado',
            width: 100,
            disableColumnMenu: true,
            disableReorder: true,
            sortable: false,
            renderCell: (params) => (
                <span
                    className={
                        params.row.requestAt !== null && !params.row.soldOut
                            ? 'request-cell'
                            : params.row.soldOut
                            ? 'sold-out-cell'
                            : params.row.expired
                            ? 'warning-cell'
                            : ''
                    }
                />
            ),
        },
    ];

    const dispatch = useDispatch();
    const [page, setPage] = useState<number>(0);
    const [rowCount, setRowCount] = useState<number>(0);
    const [rows, setRows] = useState<Array<GridRowModel>>([]);
    const [sortModel, setSortModel] = useState<GridSortModel>([]);
    const [isLoading, setLoading] = useState<boolean>(false);

    const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
    const [selectionRow, setSelectionRow] = useState<any>([]);

    const [newSelectionModel, setNewSelectionModel] = useState<GridSelectionModel>([]);

    const [editRowsModel, setEditRowsModel] = useState({});
    const [isOpen, setOpen] = useState<boolean>(false);
    const [organizations, setOrganizations] = useState<Array<Partial<OrganizationState>>>([]);
    const organizationState = useSelector((state: RootState) => state.organization);
    const userState = useSelector((state: RootState) => state.user);

    useEffect(() => {
        //join room
        if (organizationState.id !== undefined && organizations.length !== 0) {
            socket.connect();
            socket.emit('chat', {
                room: getRoomId(organizations[0].id!),
            });
        }

        return () => {
            socket.disconnect();
        };
    }, [organizationState, organizations]);

    useEffect(() => {
        callback();
        // eslint-disable-next-line
    }, [sortModel, page, reload]);

    const callback = () => {
        setLoading(true);
        getMyStoreProductsExpiresIn({ page, sort: sortModel })
            .then((res) => res.data)
            .then((data) => {
                setLoading(false);
                setRowCount(data.paging.maxItems);
                setRows(
                    data.content.map((store: any) => ({
                        ...store,
                        id: store.id,
                        product: store.product.name,
                        expireIn: store.expireIn,
                        expiration: formatExpirationDate(store.expiration),
                        batch: store.batch,
                        quantity: store.quantity,
                        expired: store.expired,
                    })),
                );
            })
            .catch(() => {
                setLoading(false);
                setRowCount(0);
                setRows([]);
            });
        getAdminChats()
            .then((res) => res.data)
            .then((data) => {
                setOrganizations(data.content);
            })
            .catch(() => {});
    };

    const handlePageChange = (page: number) => {
        setRows([]);
        setPage(page);
    };

    const handleSortModelChange = (model: GridSortModel) => {
        setSortModel(model);
    };

    const handleDialogOpen = () => {
        setOpen(true);
    };

    const handleDialogClose = () => {
        setReload(!reload);
        setOpen(false);
    };

    function sendSaleRequest() {
        handleDialogClose();
        if (selectionRow.length !== 0) {
            let items = selectionRow.map((item: any) => {
                return {
                    id: item.id,
                    quantity: item.quantity,
                    value: item.value,
                };
            });
            sendItemsToSell(items)
                .then((res) => res.data)
                .then(() => {
                    dispatch(setAlert({ isActive: true, message: 'Solicitud enviada' }));
                    setPage(0);
                    callback();
                    let message = `Se ha generado una solicitud de ${items.length} pronto vence. - Mensaje Automático -`;
                    sendChatMessage(`${organizations[0].id}`, message)
                        .then((res) => res.data)
                        .then((data) => {
                            socket.emit('new_message', {
                                room: getRoomId(organizations[0].id!),
                                ...data.data,
                            });
                            setReload(!reload);
                        })
                        .catch(() => {});
                })
                .catch(() => {
                    setReload(!reload);
                    dispatch(setAlert({ isActive: true, message: 'Se ha presentado un error, intentalo mas tarde.' }));
                });
            setReload(!reload);
        }
    }

    const handleEditRowsModelChange = useCallback(
        (model) => {
            if (model[Object.keys(model)[0]] !== undefined) {
                let id = Object.keys(model)[0];
                let quantity = model[id].quantity.value;
                if (quantity < 0) {
                    quantity = 0;
                    model[id].quantity.value = quantity;
                }
                let rowEdited = rows.find((obj) => obj.id === id);
                if (rowEdited !== undefined) {
                    rowEdited.quantity = quantity;
                }
            }
            setEditRowsModel(model);
            setNewSelectionModel(model);
        },
        [rows],
    );

    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    const [value, setValue] = React.useState('');

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setValue(event.currentTarget.innerText);
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <>
            <div className='d-flex flex-lg-row align-items-center my-3'>
                {userState.role !== UserRole.USER && (
                    <>
                        <GradientButton
                            color={'blue'}
                            className={'col-6 align-items-center'}
                            src={'sale-request.svg'}
                            title={'Enviar solicitud de venta'}
                            subtitle={''}
                            disabled={selectionModel.length === 0}
                            onClick={handleDialogOpen}
                        />
                        <div className='col-6 px-3'>
                            <p className='text-center'>
                                Selecciona tus productos y genera una solicitud de venta. Con la solicitud podrás
                                indicarle a Pilldomatch que tienes productos pronto vence para venderles.
                            </p>
                            <p>Recuerda verificar las existencias de cada producto antes de generar la solicitud.</p>
                        </div>
                    </>
                )}
            </div>

            <Divider />
            <p>{isLoading && page === 0 ? 'Cargando' : `${rowCount} Productos por expirar.`}</p>
            <DataGrid
                autoHeight
                rows={rows}
                page={page}
                columns={columns}
                pagination
                pageSize={10}
                rowCount={rowCount}
                paginationMode='server'
                onPageChange={handlePageChange}
                sortingMode='server'
                sortModel={sortModel}
                onSortModelChange={handleSortModelChange}
                loading={isLoading}
                disableColumnFilter
                disableColumnMenu
                disableColumnSelector
                disableSelectionOnClick
                hideFooter
                rowsPerPageOptions={[10]}
                checkboxSelection
                onSelectionModelChange={(newSelectionModel) => {
                    setSelectionModel(newSelectionModel);
                    const selectedIDs = new Set(newSelectionModel);
                    const selectedRowData = rows.filter((row) => selectedIDs.has(row.id.toString()));
                    setSelectionRow(selectedRowData);
                }}
                selectionModel={selectionModel}
                editRowsModel={editRowsModel}
                onEditRowsModelChange={handleEditRowsModelChange}
                components={{
                    Toolbar: Toolbar,
                    NoRowsOverlay: NoRowsOverlay,
                }}
                componentsProps={{
                    toolbar: {
                        searchBar: false,
                        expiredHint: true,
                    },
                    cell: {
                        onMouseEnter: handlePopoverOpen,
                        onMouseLeave: handlePopoverClose,
                    },
                }}
            />
            {
                value.trim() !== '' &&
                <Popover
                    sx={{
                        pointerEvents: 'none',
                    }}
                    open={open}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    onClose={handlePopoverClose}
                    disableRestoreFocus
                >
                    <Typography sx={{ p: 1 }}>{`${value}`}</Typography>
                </Popover>
            }

            <Dialog open={isOpen} onClose={handleDialogClose}>
                <DialogTitle>Enviar Solicitud</DialogTitle>
                <DialogContent>
                    <p>Recuerda verificar la cantidad de productos existentes antes de generar tu solicitud.</p>
                    <div className='d-flex flex-column'>
                        {selectionRow.map((item: any, key: number) => (
                            <div key={key} className='d-flex align-items-center justify-content-between'>
                                <div className='col-4'>
                                    <p>{item.product}</p>
                                </div>
                                <Input
                                    name='productPrice'
                                    label='Precio a vender'
                                    type='number'
                                    className='me-3'
                                    inputProps={{ min: 1 }}
                                    placeholder='Ej.: 100'
                                    validated={false}
                                    defaultValue={item.value}
                                    onChange={async (
                                        evt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
                                    ) => {
                                        item.value = parseInt(evt.target.value);
                                        let newItem: any = {
                                            ...item,
                                            id: item.id,
                                            index: key,
                                            value: parseInt(evt.target.value),
                                        };

                                        let newItems = newSelectionModel.filter((obj: any) => obj.id !== newItem.id);
                                        newItems.push(newItem);
                                        setNewSelectionModel(newItems.sort((a: any, b: any) => compare(a, b, 'index')));
                                    }}
                                />
                                <Input
                                    name='productQuantity'
                                    label='Cantidad'
                                    type='number'
                                    inputProps={{ min: 1 }}
                                    placeholder='Ej.: 100'
                                    validated={false}
                                    defaultValue={item.quantity}
                                    onChange={(evt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                                        let newItem: any = item;
                                        item.quantity = parseInt(evt.target.value);
                                        newItem = {
                                            ...item,
                                            id: item.id,
                                            index: key,
                                            quantity: parseInt(evt.target.value),
                                        };
                                        let newItems = newSelectionModel.filter((obj: any) => obj.id !== newItem.id);
                                        newItems.push(newItem);
                                        setNewSelectionModel(newItems.sort((a: any, b: any) => compare(a, b, 'index')));
                                    }}
                                />
                            </div>
                        ))}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose}>Cancelar</Button>
                    <Button onClick={sendSaleRequest}>Enviar solicitud</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default Products;
