import React from 'react';
import AddProduct from '../components/forms/AddProduct';
import Divider from '../components/Divider';
import CreateProduct from '../components/forms/CreateProduct';

function CreateStore() {
    return (
        <>
            <div className='d-flex'>
                <AddProduct />
                <Divider orientation='vertical' flexItem />
                <CreateProduct />
            </div>
        </>
    );
}

export default CreateStore;
