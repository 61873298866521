import {
    Button as MuiButton,
    Dialog as MuiDialog,
    DialogActions as MuiDialogActions,
    DialogContent as MuiDialogContent,
    DialogTitle as MuiDialogTitle,
    styled,
} from '@mui/material';

export const Dialog = styled(MuiDialog)({
    '& .MuiDialog-paper': {
        borderRadius: '12px',
    },
});

export const DialogTitle = styled(MuiDialogTitle)({
    fontFamily: 'inherit',
});

export const DialogContent = styled(MuiDialogContent)({
    fontFamily: 'inherit',
});

export const DialogActions = styled(MuiDialogActions)({
    fontFamily: 'inherit',
});

export const Button = styled(MuiButton)({
    fontFamily: 'inherit',
});
