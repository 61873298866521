import React from 'react';
import { AppBar as MuiAppbar, AppBarProps, Avatar, Badge, IconButton, Toolbar } from '@mui/material';
import { styled } from '@mui/material';
import { UilBars, UilCommentsAlt } from '@iconscout/react-unicons';
import palette from '../../scss/palette.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux';
import { Link } from 'react-router-dom';
import { UserRole } from '../../api/types';
import logo from '../../assets/logo.png';
import { setActive } from '../../redux/slices/SidebarSlice';

const CustomAppbar = styled(MuiAppbar)(({ theme }) => ({
    width: '100%',
    zIndex: theme.zIndex.drawer + 10,
    position: 'fixed',
    // backgroundColor: 'rgba(255, 0, 0, 0.4)',
    backgroundColor: palette.white,
    boxShadow: 'none',

    '& .MuiToolbar-root': {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '0 3rem 0 12px',
        height: 64,
    },

    '& .MuiToolbar-root .MuiToolbar-menu': {
        display: 'flex',
        height: '100%',

        '& .MuiToolbar-logo': {
            height: '100%',
            marginLeft: '1rem',
        },
    },

    '& .MuiToolbar-root .MuiToolbar-buttons': {
        display: 'flex',

        '& .MuiToolbar-chat': {
            a: {
                height: 42,
                width: 42,
            },
        },

        '& .MuiToolbar-profile-info': {
            display: 'flex',
            alignItems: 'center',

            '& .MuiAvatar-root': {
                height: 42,
                width: 42,
            },

            '& span': {
                marginLeft: '1rem',
                color: palette.gray2,
                fontWeight: 600,
            },
        },
    },
}));

const Appbar = React.forwardRef((props: AppBarProps, ref: React.ForwardedRef<HTMLDivElement>) => {
    const dispatch = useDispatch();
    const userState = useSelector((state: RootState) => state.user);
    const sidebarState = useSelector((state: RootState) => state.sidebar);

    const handleClick = () => {
        dispatch(
            setActive({
                isActive: !sidebarState.isActive,
            }),
        );
    };

    return (
        <CustomAppbar {...props} ref={ref}>
            <Toolbar>
                <div className='MuiToolbar-menu'>
                    <IconButton onClick={handleClick}>
                        <UilBars color={palette.primary} />
                    </IconButton>
                    <img className='MuiToolbar-logo' src={logo} alt='Pilldomatch' />
                </div>

                <div className='MuiToolbar-buttons'>
                    {userState.role !== UserRole.USER && (
                        <Badge badgeContent={0} className='MuiToolbar-chat' color='primary'>
                            <Link to='/chat' className='navbar-chat'>
                                <UilCommentsAlt />
                            </Link>
                        </Badge>
                    )}
                    <div className='MuiToolbar-profile-info'>
                        <Avatar {...stringToAvatar(userState.fullName)} />
                        <span>{userState.fullName}</span>
                    </div>
                </div>
            </Toolbar>
        </CustomAppbar>
    );
});

export const stringToAvatar = (name: string) => ({
    sx: {
        bgcolor: stringToColor(name),
        fontSize: '1rem',
        width: 48,
        height: 48,
    },
    children: `${name.split(' ')[0][0]}${
        name.split(' ')[1] !== undefined ? name.split(' ')[1][0] : name.split(' ')[0][1].toUpperCase()
    }`,
});

const stringToColor = (string: string) => {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.substr(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
};

export default Appbar;
