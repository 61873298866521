import React, { ChangeEvent } from 'react';
import { DataGrid as MuiDataGrid, DataGridProps, GridOverlay, useGridApiContext } from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';
import palette from '../scss/palette.module.scss';
import Input from './forms/Input';
import { UilSearch, UilTimes } from '@iconscout/react-unicons';
import { Box, TablePagination, Toolbar as MuiToolbar } from '@mui/material';

const DataGrid: React.FC<DataGridProps> = styled(MuiDataGrid)(({ theme }) => ({
    minHeight: 682,
    border: 'none',
    boxShadow: palette.boxShadow,
    '& .MuiToolbar-root': {
        background: palette.white,
        padding: '1rem',
        borderTopLeftRadius: palette.borderRadius,
        borderTopRightRadius: palette.borderRadius,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
        },

        '& .MuiDataGrid-addButton': {
            height: '100%',
            padding: '0.5rem 1rem',
            background: 'none',
            color: palette.gray3,
            borderRadius: palette.borderRadius,
            transition: 'background 0.25s ease',

            '&:hover': {
                background: '#F5F5F5',
            },
        },

        '& .MuiInputBase-wrapper': {
            // flex: '1 1 auto',
        },

        '& .MuiInputBase-container': {
            border: '1px solid #000000',
            borderRadius: 100,
            flex: '1 1 auto',
        },

        '& .MuiInputBase-root': {
            borderRadius: 100,
            padding: '8px 14px',

            '& input': {
                minWidth: '120px',
            },
        },

        '& .MuiDataGrid-alerts': {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            [theme.breakpoints.up('md')]: {
                flexDirection: 'row',
                alignItems: 'center',
            },
        },

        '& .MuiDataGrid-alert': {
            display: 'flex',
            alignItems: 'center',
            margin: '1rem 0 1rem 1rem',
        },

        '& .MuiDataGrid-warning': {
            content: '',
            display: 'block',
            background: '#dadada',
            width: '24px',
            minWidth: '24px',
            height: '24px',
            minHeight: '24px',
            borderRadius: '100%',
            marginRight: 10,
        },

        '& .MuiDataGrid-selected': {
            content: '',
            display: 'block',
            background: '#1976D240',
            width: '24px',
            minWidth: '24px',
            height: '24px',
            minHeight: '24px',
            borderRadius: '100%',
            marginRight: 10,
        },
        '& .MuiDataGrid-request': {
            content: '',
            display: 'block',
            background: '#c0a7ff',
            width: '24px',
            minWidth: '24px',
            height: '24px',
            minHeight: '24px',
            borderRadius: '100%',
            marginRight: 10,
        },
        '& .MuiDataGrid-sold-out': {
            content: '',
            display: 'block',
            background: '#16FF79',
            width: '24px',
            minWidth: '24px',
            height: '24px',
            minHeight: '24px',
            borderRadius: '100%',
            marginRight: 10,
        },

        '& .MuiTablePagination-root': {
            overflow: 'visible',
        },

        '& .MuiTablePagination-toolbar': {
            '& .MuiButtonBase-root': {
                border: 'none',
                color: 'rgba(0,0,0,0.87)',

                '&.Mui-disabled': {
                    opacity: 0.38,
                },
            },
        },
    },

    '& .MuiDataGrid-main': {
        borderBottomLeftRadius: palette.borderRadius,
        borderBottomRightRadius: palette.borderRadius,
    },

    '& .MuiDataGrid-columnHeaders': {
        border: 0,
        background: '#FAFAFA',
        transition: '0.25s',
    },

    '& .MuiDataGrid-columnHeaderTitle': {
        border: 0,
        // background: 'red',
        outline: 'none',
        fontWeight: 600,
        color: '#606B72',
    },
    '& .MuiDataGrid-main, & .MuiDataGrid-footerContainer': {
        background: '#FFFFFF',
    },

    '& .MuiDataGrid-row': {
        // padding:0,
        cursor: 'pointer',
        '&.Mui-selected': {
            background: '#1976D240',
        },
        '&:last-child .MuiDataGrid-cell': {
            border: 'none',
        },
    },

    '& .MuiDataGrid-cell': {
        transition: '0.25s',
        color: '#6A747A',
        fontWeight: 400,
    },

    '& .MuiDataGrid-cell, &.MuiDataGrid-columnHeader': {
        padding: '0 20px !important',
    },

    '& .MuiDataGrid-cell:focus': {
        background: 'none',
        outline: 'none !important',
    },

    '& .MuiTablePagination-actions': {
        padding: '20px 10px',
        '& .MuiButtonBase-root': {
            // background:'red',
            borderRadius: '4px',
            border: '#979797 solid 1px',
            padding: '3px',
            color: '#979797',
        },
        '& .Mui-disabled': {
            border: 'transparent solid 1px',
        },
    },

    '& .MuiDataGrid-overlay': {
        zIndex: 10,
    },

    '& .MuiDataGrid-footerContainer': {
        borderBottomLeftRadius: palette.borderRadius,
        borderBottomRightRadius: palette.borderRadius,
    },
}));

interface ToolbarProps {
    clearSearch?: () => void;
    onChange?: (evt: ChangeEvent<HTMLInputElement>) => void;
    value?: string;
    searchBar?: boolean;
    expiredHint?: boolean;
    button?: React.ReactNode;
}

export const Toolbar = ({
    clearSearch,
    onChange,
    value,
    searchBar = true,
    expiredHint = false,
    button,
}: ToolbarProps) => {
    const apiRef = useGridApiContext();

    const handlePageChange = (evt: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        apiRef.current.setPage(newPage);
    };

    return (
        <>
            <MuiToolbar>
                {button}
                {/*<div className='MuiInputBase-wrapper'>*/}
                {searchBar && (
                    <Input
                        name='search'
                        placeholder='Buscar'
                        validated={false}
                        startAdornment={<UilSearch />}
                        endAdornment={value !== '' ? <UilTimes onClick={clearSearch} /> : null}
                        value={value}
                        onChange={onChange}
                    />
                )}
                {/*</div>*/}

                <TablePagination
                    component='div'
                    count={apiRef.current.state.pagination.rowCount}
                    page={apiRef.current.state.pagination.page}
                    onPageChange={handlePageChange}
                    rowsPerPage={apiRef.current.state.pagination.pageSize}
                    rowsPerPageOptions={[apiRef.current.state.pagination.pageSize]}
                />
                {expiredHint && (
                    <div className='MuiDataGrid-alerts'>
                        <div className='MuiDataGrid-alert'>
                            <span className='MuiDataGrid-warning' />
                            <span>Producto Expirado</span>
                        </div>
                        <div className='MuiDataGrid-alert'>
                            <span className='MuiDataGrid-selected' />
                            <span>Producto Seleccionado</span>
                        </div>
                        <div className='MuiDataGrid-alert'>
                            <span className='MuiDataGrid-sold-out' />
                            <span>Producto vendido</span>
                        </div>
                        <div className='MuiDataGrid-alert'>
                            <span className='MuiDataGrid-request' />
                            <span>Producto en solicitud de venta</span>
                        </div>
                    </div>
                )}
            </MuiToolbar>
        </>
    );
};

const StyledGridOverlay = styled(GridOverlay)(({ theme }) => ({
    flexDirection: 'column',
    '& .ant-empty-img-1': {
        fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
    },
    '& .ant-empty-img-2': {
        fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
    },
    '& .ant-empty-img-3': {
        fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
    },
    '& .ant-empty-img-4': {
        fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
    },
    '& .ant-empty-img-5': {
        fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
        fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
    },
}));

export const NoRowsOverlay = () => {
    return (
        <StyledGridOverlay>
            <svg width='120' height='100' viewBox='0 0 184 152' aria-hidden focusable='false'>
                <g fill='none' fillRule='evenodd'>
                    <g transform='translate(24 31.67)'>
                        <ellipse className='ant-empty-img-5' cx='67.797' cy='106.89' rx='67.797' ry='12.668' />
                        <path
                            className='ant-empty-img-1'
                            d='M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z'
                        />
                        <path
                            className='ant-empty-img-2'
                            d='M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z'
                        />
                        <path
                            className='ant-empty-img-3'
                            d='M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z'
                        />
                    </g>
                    <path
                        className='ant-empty-img-3'
                        d='M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z'
                    />
                    <g className='ant-empty-img-4' transform='translate(149.65 15.383)'>
                        <ellipse cx='20.654' cy='3.167' rx='2.849' ry='2.815' />
                        <path d='M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z' />
                    </g>
                </g>
            </svg>
            <Box sx={{ mt: 1 }}>Sin filas</Box>
        </StyledGridOverlay>
    );
};

export default DataGrid;
