import React from 'react';
import { Divider as MuiDivider, DividerProps, styled } from '@mui/material';

const Divider: React.FC<DividerProps> = styled(MuiDivider)((props) => ({
    margin: props.orientation === 'vertical' ? '0 1rem' : '1rem 0',
    borderColor: '#F4C5DE',
    opacity: 1,

    '&:not([size])': {
        height: 'unset',
    },
}));

/*function Divider() {
    return (
        <div className='mx-auto w-100'>
            <hr />
        </div>
    );
}*/

export default Divider;
