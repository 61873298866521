import React, { useEffect, useRef, useState } from 'react';
import { getRoomId, socket } from '../api/socket';
import { Avatar, Badge } from '@mui/material';
import { UilBell, UilMessage } from '@iconscout/react-unicons';
import { useSelector } from 'react-redux';
import { RootState } from '../redux';
import {
    findOrganizationByName,
    getAdminChats,
    getChatMessages,
    getCurrentChats,
    readMessages,
    sendChatMessage,
} from '../api/api';
import { OrganizationState } from '../redux/slices/OrganizationSlice';
import useDebounce from '../utils/useDebounce';
import Autocomplete, { AutocompleteValue, ListboxComponent } from '../components/forms/Autocomplete';
import ProductSearchChat from '../components/forms/ProductSearchChat';
import { formatDateCalendar } from '../utils/format-date';
import { stringToAvatar } from '../components/navigators/Appbar';
import InfiniteScroll from 'react-infinite-scroll-component';
import { UserRole } from '../api/types';

type ChatMessage = {
    message: string;
    id: string;
    createdAt: any;
    destinationId: string;
    ownerId: string;
    seen: boolean;
    ownerName: string;
    destinationName: string;
};

/** --- ROOM ID ---
 ADMIN: chat selected (User) organization id + current (Admin) organization id
 USER - MOD : current organization id + Admin (chat-selected) organization id
 */
function ChatPage() {
    const userState = useSelector((state: RootState) => state.user);
    const organizationState = useSelector((state: RootState) => state.organization);

    const [page, setPage] = useState<number>(1);

    const [organizations, setOrganizations] = useState<Array<Partial<OrganizationState>>>([]);
    const [organizationsAutoComplete, setOrganizationsAutoComplete] = useState<
        Array<AutocompleteValue<string, string>>
    >([]);

    const [inRoom, setInRoom] = useState(false);

    const [destinationId, setDestinationId] = useState<string>('');
    const [room, setRoom] = useState<string>('');

    const [messages, setMessages] = useState<Array<ChatMessage>>([]);
    const [message, setMessage] = useState<string>('');
    const [readMessage, setReadMessage] = useState<boolean>(false);
    const chatRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLDivElement>(null);

    const [hasMore, setHasMore] = useState<boolean>(true);

    const [searchOrg, setSearchOrg] = useState('');

    const debouncedSearch = useDebounce(searchOrg, 500);

    const [isOpen, setOpen] = useState<boolean>(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [isAutocompleteLoading, setAutocompleteLoading] = useState<boolean>(false);

    const [value, setValue] = React.useState<string | null>(null);

    useEffect(() => {
        if (isOpen) {
            setOrganizationsAutoComplete([]);
            callback();
        }
        // eslint-disable-next-line
    }, [isOpen, debouncedSearch]);

    const callback = () => {
        setAutocompleteLoading(true);
        findOrganizationByName(searchOrg)
            .then((res) => res.data)
            .then((data) => {
                setAutocompleteLoading(false);
                setOrganizationsAutoComplete(
                    data.content.map((organization) => ({
                        label: organization.name,
                        value: organization.id,
                    })),
                );
            })
            .catch(() => {
                setAutocompleteLoading(false);
            });
    };

    useEffect(() => {
        if (userState.role.toLocaleLowerCase() === 'admin') {
            getCurrentChats(100)
                .then((res) => res.data)
                .then((data) => {
                    if (data.content.length === 0) throw Error;
                    setOrganizations(data.content);
                })
                .catch((err) => {});
        } else {
            getAdminChats()
                .then((res) => res.data)
                .then((data) => {
                    setOrganizations(data.content);
                })
                .catch(() => {});
        }
    }, [readMessage, userState.role]);

    useEffect(() => {
        if (room.trim() !== '') {
            setInRoom(true);
            getChatMessages(destinationId, page)
                .then((res) => res.data)
                .then((data) => {
                    if (data.content.length === 0) {
                        setHasMore(false);
                    } else {
                        setHasMore(true);
                    }
                    setMessages(data.content);
                })
                .catch(() => {
                    setHasMore(false);
                });
            if (chatRef.current) {
                chatRef.current.scrollTo(0, chatRef.current.scrollHeight);
            }
            socket.connect();
            //join room
            socket.emit('chat', { room: room });
        }

        return () => {
            socket.disconnect();
        };
        // eslint-disable-next-line
    }, [room, destinationId]);

    useEffect(() => {
        //listen message
        socket.on('message-received', (value: ChatMessage) => {
            readAllMessage(value.ownerId);
            setMessages([value, ...messages]);
        });

        return () => {
            socket.off('message-received');
        };
    }, [messages]);

    function sendMessage() {
        if (room.trim() !== '') {
            inputRef!.current!.innerText = '';
            sendChatMessage(destinationId, message)
                .then((res) => res.data)
                .then((data) => {
                    socket.emit('new_message', {
                        room: room,
                        ...data.data,
                    });
                })
                .catch(() => {});
        }
    }

    function readAllMessage(id: string) {
        setReadMessage(false);
        readMessages(id)
            .then((res) => res.data)
            .then(() => {
                setReadMessage(true);
            })
            .catch(() => {
                setReadMessage(false);
            });
    }

    function joinChat(org: any) {
        setPage(1);
        readAllMessage(`${org.id}`);
        setDestinationId(`${org.id}`);
        setRoom(getRoomId(`${org.id}`));
    }

    function handleInfiniteScroll() {
        getChatMessages(destinationId, page + 1)
            .then((res) => res.data)
            .then((data) => {
                if (data.content.length === 0) {
                    setHasMore(false);
                } else {
                    setHasMore(true);
                }
                setMessages([...messages, ...data.content]);
                setPage(page + 1);
            })
            .catch(() => {
                setHasMore(false);
            });
    }

    return (
        <>
            <div className='d-flex flex-column h-75 my-auto'>
                <div className='d-flex flex-column flex-lg-row h-100 my-4  '>
                    <div id='chats-panel' className='d-flex flex-column col-12 col-lg-4 container-chat'>
                        {userState.role === UserRole.ADMIN ? (
                            <div className='mb-3'>
                                <Autocomplete
                                    name='organization'
                                    // label='Organización'
                                    placeholder='Ej.: Pilldomatch'
                                    disablePortal
                                    disableListWrap
                                    filterOptions={() => organizationsAutoComplete}
                                    ListboxComponent={ListboxComponent}
                                    onOpen={handleOpen}
                                    onClose={handleClose}
                                    handleChange={setSearchOrg}
                                    open={isOpen}
                                    loading={isAutocompleteLoading}
                                    options={organizations}
                                    renderGroup={(params) => params}
                                    renderOption={(props, option) => [props, option.label]}
                                    validated={false}
                                    isOptionEqualToValue={() => {
                                        return true;
                                    }}
                                    value={value}
                                    onChange={(event, newValue) => {
                                        if (newValue !== null) {
                                            if (newValue.value !== undefined) {
                                                let org: Partial<OrganizationState> = {
                                                    id: newValue.value,
                                                    name: newValue.label,
                                                    unreadMessageCount: 0,
                                                };
                                                joinChat(org);
                                                setOrganizations([
                                                    org,
                                                    ...organizations.filter((obj) => obj.id !== org.id),
                                                ]);
                                                setValue(`${org.name}`);
                                            }
                                        } else {
                                            setValue('');
                                        }
                                    }}
                                />
                            </div>
                        ) : null}

                        {organizations.map((org, key) => {
                            return (
                                <div
                                    key={key}
                                    className={` bg-white-sh d-flex flex-row justify-content-between align-items-center p-3 mb-2 cp b-r-12 ${
                                        room.includes(`${org.id}`) ? 'chat-active' : ''
                                    } `}
                                    onClick={() => {
                                        joinChat(org);
                                    }}
                                >
                                    <div className='d-flex align-items-center'>
                                        <Avatar {...stringToAvatar(`${org.name}`)} />
                                        <p className='mx-2'>{org.name}</p>
                                    </div>
                                    <div>
                                        <Badge badgeContent={org.unreadMessageCount} color='primary'>
                                            <UilBell />
                                        </Badge>
                                    </div>
                                </div>
                            );
                        })}
                    </div>

                    <div className='flex-fill d-flex flex-column '>
                        {userState.isAdmin ? (
                            <div className='ps-3'>
                                <ProductSearchChat />
                            </div>
                        ) : null}
                        <div className='chat '>
                            {inRoom ? (
                                <>
                                    <div ref={chatRef} className='chat-container' id='scrollableContainer'>
                                        <InfiniteScroll
                                            dataLength={messages.length}
                                            next={handleInfiniteScroll}
                                            hasMore={hasMore}
                                            scrollThreshold={0.65}
                                            loader={
                                                <div className='d-flex justify-content-center align-items-center py-3' />
                                            }
                                            inverse={true}
                                            scrollableTarget='scrollableContainer'
                                            endMessage={
                                                <p className='text-center my-4'>
                                                    <b>No hay mas mensajes</b>
                                                </p>
                                            }
                                        >
                                            {messages.map((message, key) => {
                                                return (
                                                    <div
                                                        key={key}
                                                        className={`chat-${
                                                            message.ownerId === organizationState.id
                                                                ? 'sender'
                                                                : 'receptor'
                                                        }`}
                                                    >
                                                        <Avatar {...stringToAvatar(message.ownerName)} />
                                                        <div className='message'>
                                                            <p>{message.message}</p>
                                                        </div>
                                                        <div className='timestamp'>
                                                            <p>{formatDateCalendar(message.createdAt)}</p>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </InfiniteScroll>
                                    </div>
                                </>
                            ) : (
                                <div className='m-auto'>
                                    <p>Selecciona un chat</p>
                                </div>
                            )}
                            {inRoom ? (
                                <div className='message-input'>
                                    <span
                                        ref={inputRef}
                                        onBlur={(event) => setMessage(event.target.innerText)}
                                        role='textbox'
                                        contentEditable
                                        placeholder='Escribe tu mensaje...'
                                    />
                                    <div className='send-icon' onClick={sendMessage}>
                                        <UilMessage />
                                    </div>
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ChatPage;
