import { Tab as MuiTab, styled } from '@mui/material';
import palette from '../scss/palette.module.scss';
import { TabList as MuiTabList, TabPanel as MuiTabPanel } from '@mui/lab';

export const TabList = styled(MuiTabList)({
    '& .MuiTabs-indicator': {
        backgroundColor: palette.primary,
    },
});

export const Tab = styled(MuiTab)({
    fontFamily: 'inherit',
    textTransform: 'unset',

    '&.Mui-selected': {
        color: palette.primary,
    },
});

export const TabPanel = styled(MuiTabPanel)({
    padding: 0,
});
