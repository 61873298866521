import React, { useState } from 'react';
import Divider from '../components/Divider';
import { Tab, TabList, TabPanel } from '../components/Tab';
import { TabContext } from '@mui/lab';
import AllOrganizations from '../components/AllOrganizations';

function Organizations() {
    const [value, setValue] = useState<string>('1');

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };
    return (
        <>
            <div className='py-2'>
                <TabContext value={value}>
                    <TabList onChange={handleChange} className={'pb-2'}>
                        <Tab label='Todos' value='1' />
                        <Tab label='Con solicitudes de venta' value='2' />
                    </TabList>
                    <TabPanel value='1'>
                        <AllOrganizations />
                    </TabPanel>
                    <TabPanel value='2'>
                        <AllOrganizations onlyWithSaleRequest={true} />
                    </TabPanel>
                </TabContext>
            </div>
        </>
    );
}

export default Organizations;
