import { io } from 'socket.io-client';
import { store } from '../redux';

// socket.io url
// let url = 'http://localhost:4000';
// let url = 'https://chat-pilldomatch.anatiri.dev';
let { REACT_APP_SOCKET_URL } = process.env;

export const socket = io(`${REACT_APP_SOCKET_URL}`, { autoConnect: false });

export const getRoomId = (destinationId: string) => {
    if (store.getState().user.isAdmin) {
        return `${destinationId}${store.getState().organization.id}`;
    } else {
        return `${store.getState().organization.id}${destinationId}`;
    }
};
