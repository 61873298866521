import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { object, string } from 'yup';
import { getUserDetails, updateUser } from '../api/api';
import { UpdateUser as UpdateUserInterface, UserRole } from '../api/types';
import { setAlert } from '../redux/slices/AlertSlice';
import Input from '../components/forms/Input';
import Autocomplete from '../components/forms/Autocomplete';
import { Puff } from '@agney/react-loading';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../redux';

interface InitialValues {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    role:
        | {
              label: string;
              value: string;
          }
        | string
        | null;
    organization?: {
        label: string;
        value: string;
    };
}

function UpdateUser() {
    const { id } = useParams();

    const dispatch = useDispatch();
    const userState = useSelector((state: RootState) => state.user);

    const navigate = useNavigate();

    const [isLoading, setLoading] = useState<boolean>(false);
    const [user, setUser] = useState<InitialValues | null>(null);

    useEffect(() => {
        callback();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const roles =
        userState.role === UserRole.ADMIN
            ? [
                  {
                      label: 'Admin',
                      value: 'admin',
                  },
                  {
                      label: 'Moderador',
                      value: 'moderator',
                  },
                  {
                      label: 'Usuario',
                      value: 'user',
                  },
              ]
            : [
                  {
                      label: 'Moderador',
                      value: 'moderator',
                  },
                  {
                      label: 'Usuario',
                      value: 'user',
                  },
              ];

    const callback = () => {
        getUserDetails(id)
            .then((res) => res.data)
            .then((data) => {
                const user = data.data;
                setUser({
                    email: user.email,
                    firstName: user.firstName,
                    lastName: user.lastName,
                    password: '',
                    role: roles.find((role) => role.value === user.role) || '',
                    organization: {
                        label: user.organization?.name || '',
                        value: user.organization?.id || '',
                    },
                });
            })
            .catch((err) => {
                dispatch(setAlert({ isActive: true, message: err.response.data }));
            });
    };

    return (
        <>
            <div className='d-flex justify-content-center'>
                {user && (
                    <div className='col-12 col-xl-8 col-xxl-6'>
                        <Formik
                            initialValues={user}
                            validationSchema={object({
                                firstName: string().required(),
                                lastName: string().required(),
                                email: string().email().required(),
                                password: string(),
                                role: object({
                                    label: string().required(),
                                    value: string().required(),
                                }).typeError('Campo Obligatorio'),
                                organization: object({
                                    label: string().required(),
                                    value: string().required(),
                                }).typeError('Campo Obligatorio'),
                            })}
                            onSubmit={(values) => {
                                setLoading(true);
                                const data = { ...values };
                                delete data.organization;
                                const updated = Object.fromEntries(
                                    // @ts-ignore
                                    Object.entries(data).filter(([key, value]) => value !== user[key]),
                                );
                                if (typeof updated.role === 'object' && updated.role !== null)
                                    updated.role = updated.role.value;
                                updateUser(id!, updated as UpdateUserInterface)
                                    .then((res) => res.data)
                                    .then((data) => {
                                        setLoading(false);
                                        dispatch(
                                            setAlert({
                                                isActive: true,
                                                message: data.message,
                                            }),
                                        );
                                        setTimeout(() => {
                                            navigate(
                                                `/${userState.role === UserRole.ADMIN ? 'admin' : 'mod'}/usuarios`,
                                            );
                                        }, 3000);
                                    })
                                    .catch((err) => {
                                        setLoading(false);
                                        dispatch(
                                            setAlert({
                                                isActive: true,
                                                message: err.response.data,
                                            }),
                                        );
                                    });
                            }}
                        >
                            <Form autoComplete='off' noValidate>
                                <Input name='firstName' label='Nombre' placeholder='Ej:. Juan' type='text' />
                                <Input name='lastName' label='Apellido' placeholder='Ej:. Perez' type='text' />
                                <Input
                                    name='email'
                                    label='Email'
                                    placeholder='Ej:. juan@pilldomatch.com'
                                    type='email'
                                />
                                <Input name='password' label='Contraseña' placeholder='••••••••••••' type='password' />
                                <Autocomplete
                                    name='role'
                                    label='Rol'
                                    placeholder='Ej.: Moderador'
                                    disablePortal
                                    options={roles}
                                />
                                <Autocomplete
                                    name='organization'
                                    label='Farmacia'
                                    placeholder='Ej.: Pilldomatch'
                                    disablePortal
                                    disabled
                                    options={[]}
                                />
                                <button className='primary-button'>
                                    {isLoading && (
                                        //@ts-ignore
                                        <Puff width={20} />
                                    )}
                                    {isLoading ? 'Actualizando Usuario' : 'Actualizar Usuario'}
                                </button>
                            </Form>
                        </Formik>
                    </div>
                )}
            </div>
        </>
    );
}

export default UpdateUser;
