import { setLocale } from 'yup';

setLocale({
    mixed: {
        required: 'Campo Obligatorio',
    },
    string: {
        email: 'Formato de Email Invalido',
    },
    array: {
        min: 'Campo Obligatorio',
    },
});

export const compare = (a: any, b: any, key: string) => {
    if (a[key] < b[key]) {
        return -1;
    }
    if (a[key] > b[key]) {
        return 1;
    }
    return 0;
};
