import React, { ChangeEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../redux';
import { GridColDef, GridRowModel, GridSortModel } from '@mui/x-data-grid';
import useDebounce from '../utils/useDebounce';
import { useParams } from 'react-router-dom';
import { searchStoreProduct } from '../api/api';
import { UserRole } from '../api/types';
import DataGrid, { NoRowsOverlay, Toolbar } from './DataGrid';
import { formatExpirationDate } from '../utils/format-date';
import { Popover, Typography } from '@mui/material';

function InventorySoldOut() {
    const userState = useSelector((state: RootState) => state.user);

    const [page, setPage] = useState<number>(0);
    const [rowCount, setRowCount] = useState<number>(0);
    const [rows, setRows] = useState<Array<GridRowModel>>([]);
    const [sortModel, setSortModel] = useState<GridSortModel>([]);
    const [isLoading, setLoading] = useState<boolean>(false);
    const [search, setSearch] = useState<string>('');
    const debouncedSearch = useDebounce(search, 500);

    const { organizationId } = useParams();

    useEffect(() => {
        callback();
        if (debouncedSearch) setPage(0);
        return () => {
            setRows([]);
        };
        // eslint-disable-next-line
    }, [sortModel, page, debouncedSearch]);

    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    const [value, setValue] = React.useState('');

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setValue(event.currentTarget.innerText);
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const callback = () => {
        setLoading(true);
        searchStoreProduct({
            q: debouncedSearch,
            page: page,
            sort: sortModel,
            organization: organizationId!,
            soldOut: true,
        })
            .then((res) => res.data)
            .then((data) => {
                setLoading(false);
                setRowCount(data.paging.maxItems);
                setRows(
                    data.content.map((store) => ({
                        ...store,
                        id: store.id,
                        product: store.product.name,
                        expireIn: store.expireIn,
                        expiration: formatExpirationDate(store.expiration),
                        batch: store.batch,
                        value: store.value,
                        quantity: store.quantity,
                        expired: store.expired,
                    })),
                );
            })
            .catch(() => {
                setLoading(false);
                setRowCount(0);
                setRows([]);
            });
    };

    const handlePageChange = (page: number) => {
        setRows([]);
        setPage(page);
    };

    const handleSortModelChange = (model: GridSortModel) => {
        setSortModel(model);
    };

    const columns: GridColDef[] = [
        { field: 'product', headerName: 'Producto', flex: 1, minWidth: 200 },
        {
            field: 'expireIn',
            headerName: 'Expira en',
            flex: 1,
            minWidth: 120,
            sortable: false,
        },
        { field: 'expiration', headerName: 'Fecha de expiración', flex: 1, minWidth: 200 },
        { field: 'batch', headerName: 'Lote', flex: 1, minWidth: 200 },
        {
            field: 'value',
            headerName: 'Valor',
            flex: 1,
            minWidth: 160,
            editable: userState.role === UserRole.MODERATOR,
            type: 'number',
        },
        {
            field: 'quantity',
            headerName: 'Existencias',
            flex: 1,
            minWidth: 160,
            editable: userState.role === UserRole.MODERATOR,
            type: 'number',
        },
        {
            field: 'state',
            headerName: 'Estado',
            width: 100,
            disableColumnMenu: true,
            disableReorder: true,
            sortable: false,
            renderCell: (params) => (
                <span
                    className={
                        params.row.requestAt !== null && !params.row.soldOut
                            ? 'request-cell'
                            : params.row.soldOut
                            ? 'sold-out-cell'
                            : params.row.expired
                            ? 'warning-cell'
                            : ''
                    }
                />
            ),
        },
    ];

    return (
        <>
            <div className='d-flex align-items-center'>
                <p>{isLoading && page === 0 ? 'Cargando' : `${rowCount} Productos encontrados`}</p>
            </div>

            <div className='py-3'>
                <DataGrid
                    autoHeight
                    rows={rows}
                    page={page}
                    columns={columns}
                    pagination
                    pageSize={10}
                    rowCount={rowCount}
                    paginationMode='server'
                    onPageChange={handlePageChange}
                    sortingMode='server'
                    sortModel={sortModel}
                    onSortModelChange={handleSortModelChange}
                    loading={isLoading}
                    disableColumnFilter
                    disableColumnMenu
                    disableColumnSelector
                    disableSelectionOnClick
                    hideFooter
                    rowsPerPageOptions={[10]}
                    components={{
                        Toolbar: Toolbar,
                        NoRowsOverlay: NoRowsOverlay,
                    }}
                    componentsProps={{
                        toolbar: {
                            value: search,
                            onChange: (evt: ChangeEvent<HTMLInputElement>) => setSearch(evt.target.value),
                            clearSearch: () => setSearch(''),
                            expiredHint: true,
                        },
                        cell: {
                            onMouseEnter: handlePopoverOpen,
                            onMouseLeave: handlePopoverClose,
                        },
                    }}
                />
                {
                    value.trim() !== '' &&
                    <Popover
                        sx={{
                            pointerEvents: 'none',
                        }}
                        open={open}
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        onClose={handlePopoverClose}
                        disableRestoreFocus
                    >
                        <Typography sx={{ p: 1 }}>{`${value}`}</Typography>
                    </Popover>
                }
            </div>
        </>
    );
}

export default InventorySoldOut;
