import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { auth, getToken, setLogout } from './api';
import jwtDecode from 'jwt-decode';
import { setUser } from '../redux/slices/UserSlice';
import LoadingScreen from '../pages/LoadingScreen';
import { Navigate, Outlet } from 'react-router-dom';
import Sidebar from '../components/navigators/Sidebar';
import { setOrganizationId } from '../redux/slices/OrganizationSlice';
import { Token, UserRole } from './types';
import Appbar from '../components/navigators/Appbar';

function AdminRoute() {
    const dispatch = useDispatch();
    const [isLoading, setLoading] = useState<boolean>(true);
    const [isAuth, setAuth] = useState<boolean>(false);

    useEffect(() => {
        const authenticate = () => {
            const token = getToken();
            if (token !== null) {
                auth()
                    .then((res) => res.data)
                    .then(() => {
                        const decodedJwt = jwtDecode<Token>(token);
                        if (decodedJwt.role.toLowerCase().trim() === UserRole.ADMIN) {
                            dispatch(
                                setUser({
                                    isAuth: true,
                                    isAdmin: true,
                                    isModerator: false,
                                    role: decodedJwt.role,
                                    id: decodedJwt.sub,
                                    fullName: decodedJwt.fullName,
                                }),
                            );
                            dispatch(setOrganizationId({ id: decodedJwt.organization }));
                            setAuth(true);
                            setLoading(false);
                        } else {
                            throw Error;
                        }
                    })
                    .catch(() => {
                        setLogout();
                        setAuth(false);
                        setLoading(false);
                    });
            } else {
                setLogout();
                setAuth(false);
                setLoading(false);
            }
        };
        authenticate();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return isLoading ? (
        <LoadingScreen />
    ) : isAuth ? (
        <>
            <Appbar />
            <Sidebar>
                <Outlet />
            </Sidebar>
        </>
    ) : (
        <Navigate to='/' replace />
    );
}

export default AdminRoute;
