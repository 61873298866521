import React, { ChangeEvent, useEffect, useState } from 'react';
import DataGrid, { NoRowsOverlay, Toolbar } from './DataGrid';
import { GridColDef, GridRowsProp, GridSortModel } from '@mui/x-data-grid';
import * as Unicons from '@iconscout/react-unicons';
import ActionCell from './ActionCell';
import { deleteOrganization, searchOrg } from '../api/api';
import { Link, useNavigate } from 'react-router-dom';
import useDebounce from '../utils/useDebounce';
import { UilFocusAdd } from '@iconscout/react-unicons';
import palette from '../scss/palette.module.scss';
import { Popover, Typography } from '@mui/material';

type Props = {
    onlyWithSaleRequest?: boolean;
};
function AllOrganizations({ onlyWithSaleRequest = false }: Props) {
    const navigate = useNavigate();

    const [rows, setRows] = useState<GridRowsProp>([]);
    const [page, setPage] = useState<number>(0);
    const [rowCount, setRowCount] = useState<number>(0);
    const [sortModel, setSortModel] = useState<GridSortModel>([]);
    const [isLoading, setLoading] = useState<boolean>(true);
    const [search, setSearch] = useState('');
    const debouncedSearch = useDebounce(search, 500);

    useEffect(() => {
        callback();
        if (debouncedSearch) setPage(0);
        return () => {
            setRows([]);
        };
        // eslint-disable-next-line
    }, [debouncedSearch, page, sortModel]);

    const callback = () => {
        setLoading(true);
        searchOrg({ q: debouncedSearch, page: page, sort: sortModel, bySaleRequest: onlyWithSaleRequest })
            .then((res) => res.data)
            .then((data) => {
                setLoading(false);
                setRowCount(data.paging.maxItems);
                setRows(
                    data.content.map((organization) => ({
                        ...organization,
                        id: organization.id,
                        createdAt: new Date(organization.createdAt).toLocaleDateString(),
                        name: organization.name,
                        address: organization.address,
                        expireIn: organization.expireInCount > 0 ? organization.expireInCount : '-',
                        expired: organization.expiredCount > 0 ? organization.expiredCount : '-',
                    })),
                );
            })
            .catch(() => {
                setLoading(false);
                setRowCount(0);
                setRows([]);
            });
    };

    const handlePageChange = (page: number) => {
        setRows([]);
        setPage(page);
    };

    const handleSortModelChange = (model: GridSortModel) => {
        setSortModel(model);
    };

    const columns: GridColDef[] = [
        {
            field: 'createdAt',
            headerName: 'Fecha de creación',
            width: 200,
            sortable: false,
        },
        { field: 'name', headerName: 'Nombre', minWidth: 150, flex: 1 },
        { field: 'address', headerName: 'Dirección', minWidth: 150, flex: 1 },
        { field: 'expireIn', headerName: 'Por expirar', width: 120, sortable: false },
        {
            field: 'sentToSellCount',
            headerName: 'Estado',
            sortable: false,
            flex: 1,
            renderCell: (params) =>
                params.row.sentToSellCount !== 0 ? (
                    <div className='btn-rounded-min'>
                        <span>Solicitud de venta</span>
                    </div>
                ) : (
                    <p>-</p>
                ),
        },
        { field: 'expired', headerName: 'Expirados', width: 120, sortable: false },
        {
            disableColumnMenu: true,
            disableReorder: true,
            sortable: false,
            field: 'Settings',
            headerName: 'Ajustes',
            renderHeader: () => (
                <div style={{ padding: '0 14px' }}>
                    <Unicons.UilSetting color={'#AEB7BD'} />
                </div>
            ),
            renderCell: (params) => (
                <ActionCell
                    params={params}
                    dataCallback={callback}
                    deleteCallback={deleteOrganization}
                    redirect={`/admin/organizacion/actualizar/${params.id}`}
                    dialogTitle={`¿Eliminar farmacia ${params.row.name}?`}
                />
            ),
        },
    ];

    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    const [value, setValue] = React.useState('');

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setValue(event.currentTarget.innerText);
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <>
            <DataGrid
                autoHeight
                rows={rows}
                page={page}
                columns={columns}
                pagination
                pageSize={10}
                rowCount={rowCount}
                paginationMode='server'
                onPageChange={handlePageChange}
                sortingMode='server'
                sortModel={sortModel}
                onSortModelChange={handleSortModelChange}
                loading={isLoading}
                disableColumnFilter
                disableColumnMenu
                disableColumnSelector
                disableSelectionOnClick
                hideFooter
                rowsPerPageOptions={[10]}
                onCellClick={(params) => {
                    if (params.field !== 'Settings') navigate(`/admin/organizacion/${params.row.id}`);
                }}
                components={{
                    Toolbar: Toolbar,
                    NoRowsOverlay: NoRowsOverlay,
                }}
                componentsProps={{
                    toolbar: {
                        value: search,
                        onChange: (evt: ChangeEvent<HTMLInputElement>) => setSearch(evt.target.value),
                        clearSearch: () => setSearch(''),
                        button: (
                            <>
                                <Link to='/admin/crear' className='MuiDataGrid-addButton'>
                                    <UilFocusAdd color={palette.primary} /> Crear Farmacia
                                </Link>
                            </>
                        ),
                    },
                    cell: {
                        onMouseEnter: handlePopoverOpen,
                        onMouseLeave: handlePopoverClose,
                    },
                }}
            />
            {
                value.trim() !== '' &&
                <Popover
                    sx={{
                        pointerEvents: 'none',
                    }}
                    open={open}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    onClose={handlePopoverClose}
                    disableRestoreFocus
                >
                    <Typography sx={{ p: 1 }}>{`${value}`}</Typography>
                </Popover>
            }
        </>
    );
}

export default AllOrganizations;
