import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { TabContext } from '@mui/lab';
import { Tab, TabList, TabPanel } from '../components/Tab';
import InventoryStock from '../components/InventoryStock';
import InventorySoldOut from '../components/InventorySoldOut';

function Stores() {
    const [value, setValue] = useState<string>('1');

    const { organizationId, name } = useParams();

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    return (
        <div className='my-3'>
            {organizationId && <h1 className='title-pill'>{name}</h1>}
            <TabContext value={value}>
                <TabList onChange={handleChange}>
                    <Tab label='En Stock' value='1' />
                    <Tab label='Vendido' value='2' />
                </TabList>
                <TabPanel value='1'>
                    <InventoryStock />
                </TabPanel>
                <TabPanel value='2'>
                    <InventorySoldOut />
                </TabPanel>
            </TabContext>
        </div>
    );
}

export default Stores;
