import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './redux';
import Login from './pages/Login';
import Organizations from './pages/Organizations';
import Dashboard from './pages/Dashboard';
import AdminRoute from './api/AdminRoute';
import Users from './pages/Users';
import ProtectedRoute from './api/ProtectedRoute';
import Stores from './pages/Stores';
import DashboardUser from './pages/DashboardUser';
import ExpiresIn from './pages/ExpiresIn';
import Organization from './pages/Organization';
import { createTheme, ThemeProvider } from '@mui/material';
import { esES as coreEsES } from '@mui/material/locale';
import { esES } from '@mui/x-data-grid';
import ChatPage from './pages/ChatPage';
import CreateUserOrganization from './pages/CreateUserOrganization';
import Alert from './components/Alert';
import SaleRequest from './pages/SaleRequest';
import NotFound from './pages/NotFound';
import ModRoute from './api/ModRoute';
import DashboardMod from './pages/DashboardMod';
import CreateStore from './pages/CreateStore';
import UpdateUser from './pages/UpdateUser';
import UpdateOrganization from './pages/UpdateOrganization';

const theme = createTheme({}, esES, coreEsES);

function App() {
    return (
        <ThemeProvider theme={theme}>
            <Provider store={store}>
                <BrowserRouter>
                    <Routes>
                        <Route path='/' element={<Login />} />
                        <Route path='/admin' element={<AdminRoute />}>
                            <Route path='' element={<Dashboard />} />
                            <Route path='organizaciones' element={<Organizations />} />
                            <Route path='organizacion/:id' element={<Organization />} />
                            <Route path='inventario/:name/:organizationId' element={<Stores />} />
                            <Route path='solicitud-de-venta/:organizationId' element={<SaleRequest />} />
                            <Route path='usuarios' element={<Users />} />
                            <Route path='usuario/actualizar/:id' element={<UpdateUser />} />
                            <Route path='organizacion/actualizar/:id' element={<UpdateOrganization />} />
                            <Route path='crear' element={<CreateUserOrganization />} />
                        </Route>
                        <Route path='mod' element={<ModRoute />}>
                            <Route path='' element={<DashboardMod />} />
                            <Route path='inventario' element={<Stores />} />
                            <Route path='usuarios' element={<Users />} />
                            <Route path='usuario/actualizar/:id' element={<UpdateUser />} />
                            <Route path='inventario/agregar' element={<CreateStore />} />
                            <Route path='crear' element={<CreateUserOrganization />} />
                            <Route path='pronto-vence' element={<ExpiresIn />} />
                            <Route path='solicitud-de-venta' element={<SaleRequest />} />
                        </Route>
                        <Route path='/' element={<ProtectedRoute />}>
                            <Route path='dashboard' element={<DashboardUser />} />
                            <Route path='inventario' element={<Stores />} />
                            <Route path='pronto-vence' element={<ExpiresIn />} />
                            <Route path='chat' element={<ChatPage />} />
                            <Route path='solicitud-de-venta' element={<SaleRequest />} />
                        </Route>
                        <Route path='*' element={<NotFound />} />
                    </Routes>
                </BrowserRouter>
                <Alert />
            </Provider>
        </ThemeProvider>
    );
}

export default App;
